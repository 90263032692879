import Vue from "vue";
import SendBirdCall from "sendbird-calls";
import { DirectCallRecordOption, RecordingType } from "sendbird-calls";
import SendbirdChat from '@sendbird/chat'
import { OpenChannelModule } from '@sendbird/chat/openChannel';

class SendBirdApi {
    constructor() {
        this.appId = '69B0964F-4CC7-4E1C-96EF-AA3C04216FDB';
        this.mediaAccess = null;
        this.userId = '';
        this.call = null;
        this.connected = false;
        this.recordingId = '';
        this.recordFileName = '';
        this.sendbirdchat = null;
        this.user = null;
    }
    async init({ audio, video }) {
        SendBirdCall.init(this.appId);
        this.mediaAccess = SendBirdCall.useMedia({ audio: audio, video: video });
    }
    async authenticate(userId, nickname) {
        this.userId = userId;
        const authOption = { userId: userId };
        await SendBirdCall.authenticate(authOption, async (result, error) => {
            if (error) {
                console.log('authenticate error:', error);
            } else {
                console.log(result);
                this.sendbirdchat = SendbirdChat.init({
                    appId: this.appId,
                    modules: [
                        new OpenChannelModule(),
                    ],
                });
                try {
                    this.user = await this.sendbirdchat.connect(this.userId);
                    console.log(this.user);
                    this.user = await this.sendbirdchat.updateCurrentUserInfo({
                        nickname: nickname
                    });
                    console.log(this.user);
                } catch (err) {
                    // Handle error.
                    console.log(err);
                }
            }
        });
    }
    async endCall() {
        this.call.end();
    }
    async makeCall(userId, videoEnabled, localMediaView, remoteMediaView, onConnected, onEnded, onError, recordFileName) {
        //console.log('makeCall', userId);

        this.recordFileName = recordFileName;

        if (this.connected == false) {
            await SendBirdCall.connectWebSocket()
                .then(() => {
                    console.log('socket connected');
                    this.connected = true;
                })
                .catch((error) => {
                    console.log('socket connect error:', error);
                    this.connected = false;
                    onError(error);
                });
        }

        if (this.connected) {
            const dialParams = {
                userId: userId,
                isVideoCall: videoEnabled,
                callOption: {
                    localMediaView: localMediaView,
                    remoteMediaView: remoteMediaView,
                    audioEnabled: true,
                    videoEnabled: videoEnabled
                }
            };

            // eslint-disable-next-line no-unused-vars
            this.call = SendBirdCall.dial(dialParams, (call, error) => {
                if (error) {
                    // Dialing failed.
                    console.log('error:', error);
                    onError(error);
                }
                /*
                else {
                    console.log('result:', call);
                }
                */
            });

            // eslint-disable-next-line no-unused-vars
            this.call.onEstablished = (call) => {
                //console.log('established', call);
            };

            // eslint-disable-next-line no-unused-vars
            this.call.onConnected = async (call) => {
                console.log('recordFileName', this.recordFileName);
                //let options = new DirectCallRecordOption({ recordingType: videoEnabled ? RecordingType.LOCAL_AUDIO_REMOTE_AUDIO_AND_VIDEO : RecordingType.LOCAL_REMOTE_AUDIOS, callId: call.callId, fileName: this.recordFileName });
                let options = new DirectCallRecordOption({ recordingType: RecordingType.LOCAL_REMOTE_AUDIOS, callId: call.callId, fileName: this.recordFileName });
                // Record remote user’s audio and video and download it under the name 'FILE_NAME.mp4'.

                this.recordingId = call.startRecording(options);
                if (this.recordingId) {
                    // Recording start succeed.
                    console.log('Recording start succeed.');
                } else {
                    // Recording start failed.
                    console.log('Recording start failed.');
                }
                //console.log('connected', call);
                onConnected();
            };

            // eslint-disable-next-line no-unused-vars
            this.call.onEnded = async (call) => {
                if (this.recordingId) {
                    call.stopRecording(this.recordingId);
                }
                onEnded(call.getDuration());
            };

            this.call.onRemoteAudioSettingsChanged = () => {
            };

            this.call.onRemoteVideoSettingsChanged = () => {
            };
        }
    }
}

export default new SendBirdApi();

export const sendBirdApi = {
    install(Vue) {
        Vue.prototype.$sendBirdApi = new SendBirdApi();
    }
};

Vue.use(sendBirdApi);