import Vue from "vue";
import VueRouter from "vue-router";
import DashboardLayout from "../views/Layout/DashboardLayout.vue";
import AuthBasicLayout from "../views/Layout/AuthBasicLayout";
import DefaultLayout from "../views/Layout/DefaultLayout";
import '../plugins/firebase';
import firebase from 'firebase/compat/app';
import testMode from '../plugins/testMode';
import globalVariables from '../plugins/globalVariables';
import NewReservation from "@/views/Dashboard/NewReservation.vue";
import NewReservationPage from "@/views/Dashboard/NewReservationPage.vue";

// Dashboard pages
const Dashboard = () => import("../views/Dashboard/Dashboard.vue");
const DataManager = () => import("../views/Dashboard/DataManager.vue");
const ReservationDataManager = () => import("../views/Dashboard/ReservationDataManager.vue");
const Payment = () => import("../views/Dashboard/Payment.vue");
const HistoryInfo = () => import("../views/Dashboard/HistoryInfo.vue");
const HistoryAll = () => import("../views/Dashboard/HistoryAll.vue");
const HospitalInfo = () => import("../views/Dashboard/HospitalInfo.vue");
const PlanInfo = () => import("../views/Dashboard/PlanInfo.vue");
const PaymentCardInfo = () => import("../views/Dashboard/PaymentCardInfo.vue");
const OrderSetting = () => import("../views/Dashboard/OrderSetting.vue");
const Plans = () => import("../views/Dashboard/Plans.vue");
const Quit = () => import("../views/Dashboard/Quit.vue");
const Members = () => import("../views/Dashboard/Members.vue");
const DefaultSettings = () => import("../views/Dashboard/DefaultSettings.vue");
const DefaultSettingsForMembers = () => import("../views/Dashboard/DefaultSettingsForMembers.vue");
const HospitalPages = () => import("../views/Dashboard/HospitalPages.vue");
const HospitalSettings = () => import("../views/Dashboard/HospitalSettings.vue");
const MyInfo = () => import("../views/Dashboard/MyInfo.vue");
const CustomerServices = () => import("../views/Dashboard/CustomerServices.vue");
const CustomerServicesTemp = () => import("../views/Dashboard/CustomerServicesTemp.vue");
const ActivatePlanSubscription = () => import("../views/Dashboard/ActivatePlanSubscription.vue");
const SignIn = () => import("../views/Auth/SignIn.vue");
const SignUp = () => import("../views/Auth/SignUp.vue");
const SignUpHospitalInfo = () => import("../views/Auth/SignUpHospitalInfo.vue");
const SignUpMembers = () => import("../views/Auth/SignUpMembers.vue");
const SignUpSettings = () => import("../views/Auth/SignUpSettings.vue");
const FindId = () => import("../views/Auth/FindId.vue");
const FindPw = () => import("../views/Auth/FindPw.vue");
const PlanSubscription = () => import("../views/Auth/PlanSubscription.vue");
const PaySuccess = () => import("../views/Auth/PaySuccess.vue");
const PayFailure = () => import("../views/Auth/PayFailure.vue");
const MobilePaySuccess = () => import("../views/Auth/MobilePaySuccess.vue");
const MobilePayFailure = () => import("../views/Auth/MobilePayFailure.vue");
const ChangeCardSuccess = () => import("../views/Dashboard/ChangeCardSuccess.vue");
const ChangeCardFailure = () => import("../views/Dashboard/ChangeCardFailure.vue");
const ActivateSubscription = () => import("../views/Auth/ActivateSubscription.vue");
const RequiredPayment = () => import("../views/Auth/RequiredPayment.vue");
const DormantHospital = () => import("../views/Auth/DormantHospital.vue");
const PopupView = () => import("../views/Dashboard/PopupView.vue");
const HistoryInfoRedirect = () => import("../views/Dashboard/HistoryInfoRedirect.vue");
const Notifications = () => import("../views/Dashboard/Notifications.vue");
const HospitalPayments = () => import("../views/Dashboard/HospitalPayments.vue");
const AppUsers = () => import("../views/Dashboard/AppUsers.vue");
const ChangePasswordInformation = () => import("../views/Dashboard/ChangePasswordInformation.vue");
const QuotationRequests = () => import("../views/Dashboard/QuotationRequests.vue");
const QuotationRequest = () => import("../views/Dashboard/QuotationRequest.vue");
const Quotations = () => import("../views/Dashboard/Quotations.vue");
const Quotation = () => import("../views/Dashboard/Quotation.vue");
const Orders = () => import("../views/Dashboard/Orders.vue");
const Sellers = () => import("../views/Dashboard/Sellers.vue");
const OrderStocks = () => import("../views/Dashboard/OrderStocks.vue");
const ManageStock = () => import("../views/Dashboard/ManageStock.vue");
const Order = () => import("../views/Dashboard/Order.vue");
// const Deliverys = () => import("../views/Dashboard/Deliverys.vue");
const Delivery = () => import("../views/Dashboard/Delivery.vue");
const QuotationCustomerServices = () => import("../views/Dashboard/QuotationCustomerServices.vue");
const QuotationCustomerService = () => import("../views/Dashboard/QuotationCustomerService.vue");
const OrderStockHistorys = () => import("../views/Dashboard/OrderStockHistorys.vue");

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    meta: {
      requireAuth: true,
    },
    name: "Dashboard",
    component: DashboardLayout,
    redirect: "/pages/dashboard/dashboard",
    children: [
      {
        path: "/pages/dashboard/dashboard",
        name: "홈화면",
        component: Dashboard,
        props: true,
        meta: {
          requireAuth: true,
        },
      },
      {
        path: "/pages/dashboard/data-manager",
        name: "예약완료조회",
        component: DataManager,
        props: true,
        meta: {
          requireAuth: true,
          groupName: "진료실",
          secondGroupName: "예약관리",
        },
        children: [
          {
            path: "/pages/dashboard/data-manager/data-view",
            name: "진료정보",
            component: HistoryInfo,
            meta: {
              requireAuth: true,
              groupName: "진료실",
              secondGroupName: "예약관리",
              thirdGroupName: "예약완료조회",
            },
            props: true,
            children: [
              {
                path: "/pages/dashboard/data-manager/data-view/all",
                name: "전체보기",
                component: HistoryAll,
                meta: {
                  requireAuth: true,
                  groupName: "진료실",
                  secondGroupName: "예약관리",
                  thirdGroupName: "진료정보",
                },
                props: true,
              },
            ],
          },
          {
            path: "/pages/dashboard/data-manager/data-view-redirect",
            name: "진료정보",
            component: HistoryInfoRedirect,
            meta: {
              requireAuth: true,
              groupName: "진료실",
              secondGroupName: "예약완료조회",
            },
            props: true,
          }
        ],
      },
      {
        path: "/pages/dashboard/new-reservation",
        name: "신규예약등록",
        component: NewReservation,
        props: true,
        meta: {
          requireAuth: true,
          groupName: "진료실",
          secondGroupName: "예약관리",
        },
      },
      {
        path: "/pages/dashboard/reservation-data-manager",
        name: "예약요청조회",
        component: ReservationDataManager,
        props: true,
        meta: {
          requireAuth: true,
          groupName: "진료실",
          secondGroupName: "예약관리",
        },
      },
      {
        path: "/pages/dashboard/payment",
        name: "정산내역",
        component: Payment,
        meta: {
          requireAuth: true,
          groupName: "진료실",
        },
        props: true,
      },
      {
        path: "/pages/dashboard/hospital-info",
        name: "병원 정보 관리",
        component: HospitalInfo,
        meta: {
          requireAuth: true,
          groupName: "진료실",
          secondGroupName: "기본정보수정",
        },
      },
      {
        path: "/pages/dashboard/plan-info",
        name: "플랜정보 확인",
        component: PlanInfo,
        meta: {
          requireAuth: true,
          groupName: "진료실",
          secondGroupName: "기본정보수정",
        },
      },
      {
        path: "/pages/dashboard/payment-card-info",
        name: "결제카드 관리",
        component: PaymentCardInfo,
        props: true,
        meta: {
          requireAuth: true,
          groupName: "진료실",
          secondGroupName: "기본정보수정",
        },
      },
      {
        path: "/pages/dashboard/order-setting",
        name: "구매 설정",
        component: OrderSetting,
        meta: {
          requireAuth: true,
          groupName: "진료실",
          secondGroupName: "기본정보수정",
        },
      },
      {
        path: "/pages/dashboard/activate-subscription",
        name: "플랜 재가입",
        component: ActivatePlanSubscription,
        props: true,
        meta: {
          requireAuth: true,
          groupName: "진료실",
          secondGroupName: "기본정보수정",
        },
      },
      {
        path: "/pages/dashboard/plans",
        name: "플랜 변경하기",
        component: Plans,
        meta: {
          requireAuth: true,
          groupName: "진료실",
          secondGroupName: "기본정보수정",
          thirdGroupName: "플랜정보 확인",
        },
      },
      {
        path: "/pages/dashboard/quit",
        name: "해지하기",
        component: Quit,
        meta: {
          requireAuth: true,
          groupName: "진료실",
          secondGroupName: "기본정보수정",
          thirdGroupName: "플랜정보 확인",
        },
      },
      {
        path: "/pages/dashboard/change-card-success",
        name: "변경성공",
        component: ChangeCardSuccess,
        meta: {
          requireAuth: true,
          groupName: "진료실",
          secondGroupName: "기본정보수정",
          thirdGroupName: "플랜정보 확인",
        },
      },
      {
        path: "/pages/dashboard/change-card-failure",
        name: "변경실패",
        component: ChangeCardFailure,
        meta: {
          requireAuth: true,
          groupName: "진료실",
          secondGroupName: "기본정보수정",
          thirdGroupName: "플랜정보 확인",
        },
      },
      {
        path: "/pages/dashboard/default-settings",
        name: "기본정보수정",
        component: DefaultSettings,
        meta: {
          requireAuth: true,
          groupName: "진료실",
          secondGroupName: "병원관리",
        },
        props: true,
      },
      {
        path: "/pages/dashboard/default-settings-for-members",
        name: "기본정보수정",
        component: DefaultSettingsForMembers,
        meta: {
          requireAuth: true,
          groupName: "진료실",
          secondGroupName: "병원관리",
        },
        props: true,
      },
      {
        path: "/pages/dashboard/members",
        name: "의료진/직원관리",
        component: Members,
        meta: {
          requireAuth: true,
          groupName: "진료실",
          secondGroupName: "병원관리",
        },
      },
      {
        path: "/pages/dashboard/hospital-pages",
        name: "병원페이지관리",
        component: HospitalPages,
        meta: {
          requireAuth: true,
          groupName: "진료실",
          secondGroupName: "병원관리",
        },
      },
      {
        path: "/pages/dashboard/hospital-settings",
        name: "진료환경설정",
        component: HospitalSettings,
        meta: {
          requireAuth: true,
          groupName: "진료실",
          secondGroupName: "병원관리",
        },
        props: true,
      },
      {
        path: "/pages/dashboard/notifications",
        name: "푸시관리",
        component: Notifications,
        meta: {
          requireAuth: true,
          groupName: "진료실",
          secondGroupName: "병원관리",
        },
      },
      {
        path: "/pages/dashboard/hospital-payments",
        name: "결제내역",
        component: HospitalPayments,
        meta: {
          requireAuth: true,
          groupName: "진료실",
          secondGroupName: "병원관리",
        },
      },
      {
        path: "/pages/dashboard/app-users",
        name: "고객관리",
        component: AppUsers,
        meta: {
          requireAuth: true,
          groupName: "진료실"
        },
      },
      {
        path: "/pages/dashboard/quotation-requests",
        name: "견적요청관리",
        component: QuotationRequests,
        meta: {
          requireAuth: true,
          groupName: "진료실",
          secondGroupName: "구매관리",
        },
        props: true,
      },
      {
        path: "/pages/dashboard/quotation-request",
        name: "견적요청정보",
        component: QuotationRequest,
        meta: {
          requireAuth: true,
          groupName: "진료실",
          secondGroupName: "구매관리",
        },
        props: true,
      },
      {
        path: "/pages/dashboard/quotations",
        name: "견적관리",
        component: Quotations,
        meta: {
          requireAuth: true,
          groupName: "진료실",
          secondGroupName: "구매관리",
        },
        props: true,
      },
      {
        path: "/pages/dashboard/orders",
        name: "발주관리",
        component: Orders,
        meta: {
          requireAuth: true,
          groupName: "진료실",
          secondGroupName: "구매관리",
        },
        props: true,
      },
      {
        path: "/pages/dashboard/sellers",
        name: "거래처현황",
        component: Sellers,
        meta: {
          requireAuth: true,
          groupName: "진료실",
          secondGroupName: "구매관리",
        },
        props: true,
      },
      {
        path: "/pages/dashboard/stocks",
        name: "재고관리_old",
        component: OrderStocks,
        meta: {
          requireAuth: true,
          groupName: "진료실",
          secondGroupName: "구매관리",
        },
        props: true,
      },
      {
        path: "/pages/dashboard/manage-stock",
        name: "재고관리",
        component: ManageStock,
        meta: {
          requireAuth: true,
          groupName: "진료실",
          secondGroupName: "구매관리",
        },
        props: true,
      },
      {
        path: "/pages/order-stock-historys",
        name: "이력보기",
        component: OrderStockHistorys,
      },
      {
        path: "/pages/dashboard/quotation",
        name: "견적정보",
        component: Quotation,
        meta: {
          requireAuth: true,
          groupName: "진료실",
          secondGroupName: "구매관리",
        },
        props: true,
      },
      {
        path: "/pages/dashboard/order",
        name: "발주정보",
        component: Order,
        meta: {
          requireAuth: true,
          groupName: "진료실",
          secondGroupName: "구매관리",
        },
        props: true,
      },
      /*
      {
        path: "/pages/dashboard/deliverys",
        name: "납품완료관리",
        component: Deliverys,
        meta: {
          requireAuth: true,
          groupName: "진료실",
          secondGroupName: "구매관리",
        },
        props: true,
      },
      */
      {
        path: "/pages/dashboard/delivery",
        name: "납품정보",
        component: Delivery,
        meta: {
          requireAuth: true,
          groupName: "진료실",
          secondGroupName: "구매관리",
        },
        props: true,
      },
      {
        path: "/pages/dashboard/quotation-customer-services",
        name: "상담관리",
        component: QuotationCustomerServices,
        meta: {
          requireAuth: true,
          groupName: "진료실",
          secondGroupName: "구매관리",
        },
        props: true,
      },
      {
        path: "/pages/dashboard/quotation-customer-service",
        name: "상담정보",
        component: QuotationCustomerService,
        meta: {
          requireAuth: true,
          groupName: "진료실",
          secondGroupName: "구매관리",
        },
        props: true,
      },
      {
        path: "/pages/dashboard/my-info",
        name: "마이페이지",
        component: MyInfo,
        meta: {
          requireAuth: true,
          groupName: "기타",
        },
      },
      {
        path: "/pages/dashboard/customer-services",
        name: "고객센터",
        component: CustomerServices,
        meta: {
          requireAuth: true,
          groupName: "기타",
        },
        props: true,
      },
      {
        path: "/pages/dashboard/customer-services-temp",
        name: "고객센터",
        component: CustomerServicesTemp,
        meta: {
          requireAuth: true,
          groupName: "기타",
        },
        props: true,
      },
    ],
  },
  {
    path: "/pages/dashboard/popup-view",
    name: "팝업",
    component: PopupView,
  },
  {
    path: '/',
    name: "Auth",
    redirect: "/pages/auth/signin",
    component: AuthBasicLayout,
    meta: {
      requireAuth: false
    },
    children: [
      {
        path: "/pages/auth/signin",
        name: "로그인",
        component: SignIn,
        meta: {
          requireAuth: false
        },
      },
      {
        path: "/pages/auth/signup",
        name: "회원가입",
        component: SignUp,
        props: true,
        meta: {
          requireAuth: false
        },
      },
      {
        path: "/pages/auth/signup-hospital",
        name: "병원정보입력",
        component: SignUpHospitalInfo,
        props: true,
        meta: {
          requireAuth: true
        },
      },
      {
        path: "/pages/auth/signup-members",
        name: "의료진직원추가",
        component: SignUpMembers,
        props: true,
        meta: {
          requireAuth: true
        },
      },
      {
        path: "/pages/auth/signup-settings",
        name: "초기진료환경설정",
        component: SignUpSettings,
        props: true,
        meta: {
          requireAuth: true
        },
      },
      {
        path: "/pages/auth/findid",
        name: "아이디 찾기",
        component: FindId,
        meta: {
          requireAuth: false
        },
      },
      {
        path: "/pages/auth/findpw",
        name: "비밀번호 찾기",
        component: FindPw,
        meta: {
          requireAuth: false
        },
      },
      {
        path: "/pages/auth/plan-subscription",
        name: "결제",
        component: PlanSubscription,
        props: true,
        meta: {
          requireAuth: false
        },
      },
      {
        path: "/pages/auth/pay-success",
        name: "결제성공",
        component: PaySuccess,
        props: true,
        meta: {
          requireAuth: false
        },
      },
      {
        path: "/pages/auth/pay-failure",
        name: "결제실패",
        component: PayFailure,
        props: true,
        meta: {
          requireAuth: false
        },
      },
      {
        path: "/pages/auth/activate-subscription",
        name: "재가입",
        component: ActivateSubscription,
        props: true,
        meta: {
          requireAuth: true
        },
      },
      {
        path: "/pages/auth/required-payment",
        name: "재결제",
        component: RequiredPayment,
        props: true,
        meta: {
          name: '재결제',
          requireAuth: true,
          props: true
        },
      },
      {
        path: "/pages/auth/dormant-hospital",
        name: "휴면해제",
        component: DormantHospital,
        props: true,
        meta: {
          name: '휴면해제',
          requireAuth: true,
          props: true
        },
      },
    ],
  },
  {
    path: "/mobile-pay-success",
    name: "모바일결제성공",
    component: MobilePaySuccess,
    meta: {
      requireAuth: false
    },
  },
  {
    path: "/mobile-pay-failure",
    name: "모바일결제실패",
    component: MobilePayFailure,
    meta: {
      requireAuth: false
    },
  },
  {
    path: "/pages/change-password-information",
    name: "비밀번호 변경 안내",
    component: ChangePasswordInformation,
    meta: {
      requireAuth: false
    },
  },
  {
    path: "/",
    name: "Default",
    redirect: "/pages/new-reservation-page",
    component: DefaultLayout,
    meta: {
      requireAuth: false
    },
    children: [
      {
        path: "/pages/new-reservation-page",
        name: "신규예약등록",
        component: NewReservationPage,
        meta: {
          requireAuth: false
        },
      },
    ],
  },
];

const router = new VueRouter({
  duplicateNavigationPolicy: "ignore",
  routes,
});

router.beforeEach((to, from, next) => {
  let nextPath = null;
  if (to.matched.some((record) => record.meta.requireAuth)) {
    if (firebase.auth().currentUser == null) {
      if (testMode.isTestMode === false) {
        nextPath = "/pages/auth/signin";
      }
    } else if (globalVariables.requiredPayment) {
      if (to.meta.name !== "재결제") {
        nextPath = "/pages/auth/required-payment";
      }
    }
  }

  if (nextPath == null) {
    if (firebase.auth().currentUser != null) {
      let userStatusDatabaseRef = firebase
        .database()
        .ref("/hospital_user_status/" + firebase.auth().currentUser.uid);
      let isOnlineForDatabase = {
        state: "online",
        last_changed: firebase.database.ServerValue.TIMESTAMP,
        screen: to.path,
      };
      userStatusDatabaseRef.update(isOnlineForDatabase);
    }
    next();
  } else {
    if (firebase.auth().currentUser != null) {
      let userStatusDatabaseRef = firebase
        .database()
        .ref("/hospital_user_status/" + firebase.auth().currentUser.uid);
      let isOnlineForDatabase = {
        state: "online",
        last_changed: firebase.database.ServerValue.TIMESTAMP,
        screen: nextPath,
      };
      userStatusDatabaseRef.update(isOnlineForDatabase);
    }
    next({ path: nextPath });
  }
});

export default router;
