<template>
<v-app>
    <div class="d-flex justify-start" style="width:100%;">
        <div style="width:100%">
            <fade-transition
                :duration="200"
                origin="center top"
                mode="out-in">
                <router-view></router-view>
            </fade-transition>
        </div>
    </div>
</v-app>
</template>

<script>
import {
    FadeTransition
} from "vue2-transitions";

export default {
    components: {
        FadeTransition,
    },
    data() {
        return {
        };
    },
    methods: {
    },
    async mounted() {
    },
};
</script>

<style lang="scss"></style>
