<template>
  <v-navigation-drawer
    style="width: 210px"
    touchless
    mobile-breakpoint="600"
    fixed
    app
    floating
    :expand-on-hover="mini"
    :value="drawer"
    :right="$vuetify.rtl"
    class="my-4 ms-3 me-0 border-radius-lg"
    :class="!$vuetify.breakpoint.xs ? '' : 'bg-white'"
    :data-color="sidebarColor"
    :data-theme="sidebarTheme"
  >
    <div
      class="v-navigation-drawer-brand d-flex justify-center align-center mt-4"
      style="cursor: pointer"
      @click="$emit('goHome')"
    >
      <!--v-img
          :src="require('@/assets/logo2.png')"
          height="70"
          contain
          class="mt-6"
          style="cursor:pointer"
          @click="$emit('goHome')" /-->
      <div>
        <v-img :src="require('@/assets/logo3.png')" width="40" />
      </div>
      <div class="text-primary text-h6 font-weight-bold ms-2">
        <div class="mt-1">{{ hospitalName }}</div>
        <div class="mt-n1">진료실</div>
      </div>
    </div>
    <!--div class="v-navigation-drawer-brand d-flex justify-center align-center">
        <h6
            class="text-h6 font-weight-bolder text-primary mt-2"
            style="cursor:pointer"
            @click="$emit('goHome')">
            {{hospitalName}}
        </h6>
    </div-->

    <hr class="horizontal dark mt-6 mb-4" />

    <v-list nav dense>
      <div v-for="item in items" :key="item.title">
        <h5
          v-if="item.title != '홈'"
          class="
            text-uppercase text-caption
            ls-0
            font-weight-bolder
            p-0
            text-muted
            mx-0
            mt-4
            mb-2
            ps-2
            d-none-mini
            white-space-nowrap
          "
        >
          {{ item.title }}
        </h5>

        <div class="pb-1 mx-0">
          <div v-for="child in item.items" :key="child.title">
            <v-list-group
              v-if="child.items"
              :ripple="false"
              :key="child.title"
              v-model="child.active"
              append-icon="fas fa-angle-down"
              active-class="item-active"
            >
              <template v-slot:activator>
                <v-list-item-icon
                  class="
                    shadow
                    border-radius-md
                    me-2
                    align-center
                    justify-center
                    pa-0
                  "
                >
                  <div class="text-sm">
                    <i
                      :class="child.icon"
                      :style="child.active ? 'color:white' : 'color:#757575'"
                    />
                  </div>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                    v-text="child.title"
                    class="ms-1"
                  ></v-list-item-title>
                </v-list-item-content>
              </template>

              <v-list-item
                :ripple="false"
                link
                class="mb-0 no-default-hover"
                :class="child.active ? 'item-active' : ''"
                v-for="child2 in child.items"
                :key="child2.title"
                :to="child2.link"
              >
                <span class="v-list-item-mini"></span>
                <v-list-item-content class="ms-4 ps-4" v-if="!child2.items">
                  <v-list-item-title
                    v-text="child2.title"
                    @click="onClickChild2($event, child, child2)"
                  ></v-list-item-title>
                </v-list-item-content>

                <v-list-item-content class="ms-4 ps-4 py-0" v-if="child2.items">
                  <v-list-group
                    prepend-icon=""
                    :ripple="false"
                    sub-group
                    no-action
                    v-model="child2.active"
                  >
                    <template v-slot:activator>
                      <v-list nav dense class="pa-0">
                        <v-list-group
                          :ripple="false"
                          append-icon="fas fa-angle-down me-auto ms-1"
                          active-class="item-active"
                          class="mb-0"
                        >
                          <template v-slot:activator class="mb-0">
                            <v-list-item-content class="py-0">
                              <v-list-item-title
                                v-text="child2.title"
                              ></v-list-item-title>
                            </v-list-item-content>
                          </template>
                        </v-list-group>
                      </v-list>
                    </template>

                    <v-list-item
                      v-for="child3 in child2.items"
                      :ripple="false"
                      :key="child3.title"
                      :to="child3.link"
                      @click="onClickChild3($event, child, child2, child3)"
                    >
                      <v-list-item-content>
                        <span class="v-list-item-mini"></span>
                        <v-list-item-title
                          v-text="child3.title"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-group>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>

            <v-list-item
              v-else
              :ripple="false"
              :key="child.title"
              :to="child.link"
              @click="
                deactivateAll();
                child.active = true;
              "
            >
              <v-list-item-icon
                class="
                  shadow
                  border-radius-md
                  me-2
                  align-center
                  justify-center
                  pa-0
                "
              >
                <div class="text-sm">
                  <i :class="child.icon" :style="getChildIconColor(child)" />
                </div>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title
                  v-text="child.title"
                  class="ms-1"
                ></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>
        </div>
      </div>
    </v-list>
    <div class="d-flex justify-start align-center ms-4 mt-2">
      <div
        class="d-flex justify-center align-center"
        style="cursor: pointer"
        @click="urlOpen('http://pf.kakao.com/_nujgb/chat')"
      >
        <img
          src="@/assets/talk1.png"
          style="width: 65px; height: 29px"
          alt=""
        />
        <img
          src="@/assets/talk2.png"
          style="width: 30px; height: 30px; margin-left: 4px"
         alt=""/>
      </div>
    </div>
    <div
      class="
        text-sm
        d-flex
        justify-start
        align-center
        font-weight-600
        text-capitalize
        cursor-pointer
        pt-3
        px-5
      "
      @click="logout"
    >
      <v-icon size="16" class="me-2">fa fa-sign-out-alt</v-icon>
      <span class="d-sm-inline font-weight-bold d-none text-body">
        로그아웃
      </span>
    </div>
    <div style="background-color: transparent" class="pt-12">
      <div class="text-xs text-light">상호명 : 주식회사 플라이닥터</div>
      <div class="text-xs text-light">사업자등록번호 : 188-81-02379</div>
      <div class="text-xs text-light">대표자명 : 이동진</div>
      <div class="text-xs text-light">
        사업장주소지 : 서울특별시 강남구 테헤란로 521, 29층(삼성동,
        파르나스타워)
      </div>
      <div class="text-xs text-light">통신판매업번호 : 2022-서울강남-04256</div>
      <div class="text-xs text-light">고객센터 : 1533-3965</div>
      <div style="height: 50px"></div>
    </div>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: "drawer",
  props: {
    drawer: {
      type: Boolean,
      default: null,
    },
    sidebarColor: {
      type: String,
      default: "success",
    },
    sidebarTheme: {
      type: String,
      default: "transparent",
    },
    items: {
      type: Array,
      default: null,
    },
  },
  data: () => ({
    mini: false,
    togglerActive: false,
    thirdLevelSimple: [
      "Third level menu",
      "Just another link",
      "One last link",
    ],
    hospitalName: "",
  }),
  methods: {
    urlOpen(url) {
      window.open(url, "_blank");
    },
    onClickChild2(event, child1, child2) {
      if (event !== null && event !== undefined) {
        this.listClose(event);
      }
      this.deactivateAll();
      child1.active = true;
      child2.active = true;
    },
    onClickChild3(event, child1, child2, child3) {
      if (event !== null && event !== undefined) {
        this.listClose(event);
      }
      this.deactivateAll();
      child1.active = true;
      child2.active = true;
      child3.active = true;
    },
    listClose(event) {
      let items;
      let headers;
      let groups;
      let currentEl;

      if (
        document.querySelectorAll(
          ".mb-0.v-list-item.v-list-item--link.item-active"
        )
      ) {
        items = document.querySelectorAll(
          ".mb-0.v-list-item.v-list-item--link.item-active"
        );
      }

      if (
        document.querySelectorAll(
          ".mb-0.v-list-item.v-list-item--link .v-list-group__header.v-list-item--active"
        )
      ) {
        headers = document.querySelectorAll(
          ".mb-0.v-list-item.v-list-item--link .v-list-group__header.v-list-item--active"
        );
      }

      if (
        document.querySelectorAll(
          ".mb-0.v-list-item.v-list-item--link .v-list-group .v-list .v-list-group.v-list-group--active, .mb-0.v-list-item.v-list-item--link .v-list-group.v-list-group--active"
        )
      ) {
        groups = document.querySelectorAll(
          ".mb-0.v-list-item.v-list-item--link .v-list-group .v-list .v-list-group.v-list-group--active, .mb-0.v-list-item.v-list-item--link .v-list-group.v-list-group--active"
        );
      }

      if (
        event.target.closest(
          ".mb-0.v-list-item.v-list-item--link .v-list-item__content.ms-6 .v-list-group"
        )
      ) {
        currentEl = event.target.closest(
          ".mb-0.v-list-item.v-list-item--link .v-list-item__content.ms-6 .v-list-group"
        );
      }

      if (items != null) {
        for (var i = 0; i < items.length; i++) {
          items[i].classList.remove("item-active");
        }
      }

      if (headers != null) {
        for (var j = 0; j < headers.length; j++) {
          headers[j].classList.remove(
            "v-list-item--active",
            "item-active",
            "primary--text"
          );
          headers[j].setAttribute("aria-expanded", false);
        }
      }

      if (groups != null) {
        for (var k = 0; k < groups.length; k++) {
          groups[k].classList.remove("v-list-group--active", "primary--text");
        }
      }

      if (event.target.closest(".mb-0.v-list-item.v-list-item--link")) {
        event.target
          .closest(".mb-0.v-list-item.v-list-item--link")
          .classList.add("item-active");
      }

      if (currentEl != null) {
        currentEl
          .querySelector(".v-list-group__header")
          .classList.add("v-list-item--active", "item-active");
      }
    },
    deactivateAll() {
      for (var i = 0; i < this.items.length; i++) {
        var item = this.items[i];
        item.active = false;
        if (item.items) {
          for (var j = 0; j < item.items.length; j++) {
            var child = item.items[j];
            child.active = false;
          }
        }
      }
    },
    getChildIconColor(child) {
      var color = "color:#757575";
      if (this.$route.path === child.link) {
        color = "color:white";
      } else if (child.title === "예약완료조회") {
        if (this.$route.name === "진료정보") {
          color = "color:white";
        } else if (this.$route.name === "전체보기") {
          color = "color:white";
        }
      }
      return color;
    },
    async logout() {
      await this.$auth.logout();
    },
  },
  async mounted() {
    let user = this.$auth.currentUser();
    try {
      this.hospitalWorker = await this.$database.loadHospitalWorker(user.uid);
      let hospital = await this.$database.loadHospital(
        this.hospitalWorker.hospitalId
      );
      this.hospitalName = hospital.name;
    } catch (e) {
      console.log(e);
    }
  },
};
</script>
