import Vue from "vue";

const testModeValue = {
  isTestMode: false,
  noPhoneAuth: false,
}

export default testModeValue;

export const testMode = {
  install(Vue) {
    Vue.prototype.$testMode = testModeValue
  }
};

Vue.use(testMode);
