import Vue from "vue";

const globalValue = {
    requiredPayment: false,
}

export default globalValue;

export const globalVariables = {
    install(Vue) {
        Vue.prototype.$globalVariables = globalValue
    }
};

Vue.use(globalVariables);
