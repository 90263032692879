<template>
<div>
    <v-container fluid class="px-6 py-6">
        <v-row>
            <v-col>
                <v-card class="card-shadow card-padding border-radius-xl" v-if="$utils.isEmpty(hospital)==false">
                    <v-card-title class="pt-0 text-h5 text-typo justify-center">신규예약</v-card-title>
                    <v-card-text>
                        <v-textarea
                            readonly
                            :value="policyText1"
                            hide-details
                            class="
                                            input-style
                                            font-size-input
                                            text-light-input
                                            placeholder-lighter
                                            "
                            dense
                            flat
                            filled
                            solo
                            height="200"></v-textarea>
                        <div class="d-flex justify-center align-center mt-2">
                            <v-checkbox
                                v-model="policy1Checked"
                                color="#69CBBC"
                                :ripple="false"
                                class="mx-0 mb-1 mt-0 checkbox-custom checkbox-thinner"
                                hide-details>
                                <template v-slot:label>
                                    <div class="text-md text-body">위 약관에 동의합니다.</div>
                                </template>
                            </v-checkbox>
                        </div>
                        <v-textarea
                            readonly
                            :value="policyText2"
                            hide-details
                            class="
                                            input-style
                                            font-size-input
                                            text-light-input
                                            placeholder-lighter
                                            mt-4
                                            "
                            dense
                            flat
                            filled
                            solo
                            height="200"></v-textarea>
                        <div class="d-flex justify-center align-center mt-2">
                            <v-checkbox
                                v-model="policy2Checked"
                                color="#69CBBC"
                                :ripple="false"
                                class="mx-0 mb-1 mt-0 checkbox-custom checkbox-thinner"
                                hide-details>
                                <template v-slot:label>
                                    <div class="text-md text-body">위 약관에 동의합니다.</div>
                                </template>
                            </v-checkbox>
                        </div>
                        <div class="mt-4">
                            <label class="text-sm text-typo font-weight-bolder ms-1">예약일자</label>
                            <div @click="changeDateDialog=true" style="cursor:pointer">
                                <v-text-field
                                    disabled
                                    v-model="item.reservationDate"
                                    hide-details
                                    outlined
                                    color="rgba(0,0,0,.6)"
                                    light
                                    class="
                                                font-size-input
                                                placeholder-lighter
                                                text-light-input
                                                border border-radius-md
                                                mt-1
                                                "
                                    style="cursor:pointer">
                                </v-text-field>
                            </div>
                        </div>
                        <div class="mt-3">
                            <label class="text-sm text-typo font-weight-bolder ms-1">예약시간</label>
                            <div @click="showChangeTimeDialog" style="cursor:pointer">
                                <v-text-field
                                    disabled
                                    v-model="item.reservationTime"
                                    hide-details
                                    outlined
                                    color="rgba(0,0,0,.6)"
                                    light
                                    class="
                                                font-size-input
                                                placeholder-lighter
                                                text-light-input
                                                border border-radius-md
                                                mt-1
                                                "
                                    style="cursor:pointer">
                                </v-text-field>
                            </div>
                        </div>
                        <div class="mt-3">
                            <label class="text-sm text-typo font-weight-bolder ms-1">예약자 이름</label>
                            <v-text-field
                                v-model="item.name"
                                hide-details
                                outlined
                                color="rgba(0,0,0,.6)"
                                light
                                class="
                                                font-size-input
                                                placeholder-lighter
                                                text-light-input
                                                border border-radius-md
                                                mt-1
                                                ">
                            </v-text-field>
                        </div>
                        <div class="mt-3">
                            <label class="text-sm text-typo font-weight-bolder ms-1">예약자 핸드폰번호</label>
                            <div class="d-flex justify-start align-center">
                                <div style="width:100%">
                                    <v-text-field
                                        :value="phone1"
                                        hide-details
                                        outlined
                                        color="rgba(0,0,0,.6)"
                                        light
                                        class="
                                            font-size-input
                                            placeholder-lighter
                                            text-light-input
                                            border border-radius-md
                                            "
                                        @input="phone1=$utils.onInput($event, $refs.phone1, /[0-9]/g, 3, $refs.phone2, showNumOnly)"
                                        ref="phone1">
                                    </v-text-field>
                                </div>
                                <div class="opacity-8 mb-0 text-md text-typo text-left" style="margin-left:5px;margin-right:5px">-</div>
                                <div style="width:100%">
                                    <v-text-field
                                        :value="phone2"
                                        hide-details
                                        outlined
                                        color="rgba(0,0,0,.6)"
                                        light
                                        class="
                                                font-size-input
                                                placeholder-lighter
                                                text-light-input
                                                border border-radius-md
                                                "
                                        @input="phone2=$utils.onInput($event, $refs.phone2, /[0-9]/g, 4, $refs.phone3, showNumOnly)"
                                        ref="phone2">
                                    </v-text-field>
                                </div>
                                <div class="opacity-8 mb-0 text-md text-typo text-left" style="margin-left:5px;margin-right:5px">-</div>
                                <div style="width:100%">
                                    <v-text-field
                                        :value="phone3"
                                        hide-details
                                        outlined
                                        color="rgba(0,0,0,.6)"
                                        light
                                        class="
                          font-size-input
                          placeholder-lighter
                          text-light-input
                          border border-radius-md
                        "
                                        @input="phone3=$utils.onInput($event, $refs.phone3, /[0-9]/g, 4, null, showNumOnly)"
                                        ref="phone3">
                                    </v-text-field>
                                </div>
                            </div>
                        </div>
                        <div class="mt-3">
                            <label class="text-sm text-typo font-weight-bolder ms-1">예약자 생년월일</label>
                            <div class="d-flex justify-start align-center mt-1">
                                <div>
                                    <v-text-field
                                        :value="birthYear"
                                        hide-details
                                        outlined
                                        color="rgba(0,0,0,.6)"
                                        light
                                        class="
                                                        font-size-input
                                                        placeholder-lighter
                                                        text-light-input
                                                        border border-radius-md
                                                        "
                                        @input="birthYear=$utils.onInput($event, $refs.birthYear, /[0-9]/g, 4, $refs.birthMonth, showNumOnly)"
                                        ref="birthYear">
                                    </v-text-field>
                                </div>
                                <div class="opacity-8 mb-0 text-md text-typo text-left" style="margin-left:5px;margin-right:10px">년</div>
                                <div>
                                    <v-text-field
                                        :value="birthMonth"
                                        hide-details
                                        outlined
                                        color="rgba(0,0,0,.6)"
                                        light
                                        class="
                                    font-size-input
                                    placeholder-lighter
                                    text-light-input
                                    border border-radius-md
                                    "
                                        @input="birthMonth=$utils.onInput($event, $refs.birthMonth, /[0-9]/g, 2, $refs.birthDay, showNumOnly)"
                                        ref="birthMonth">
                                    </v-text-field>
                                </div>
                                <div class="opacity-8 mb-0 text-md text-typo text-left" style="margin-left:5px;margin-right:10px">월</div>
                                <div>
                                    <v-text-field
                                        :value="birthDay"
                                        hide-details
                                        outlined
                                        color="rgba(0,0,0,.6)"
                                        light
                                        class="
                                    font-size-input
                                    placeholder-lighter
                                    text-light-input
                                    border border-radius-md
                                    "
                                        @input="birthDay=$utils.onInput($event, $refs.birthDay, /[0-9]/g, 2, null, showNumOnly)"
                                        ref="birthDay">
                                    </v-text-field>
                                </div>
                                <div class="opacity-8 mb-0 text-md text-typo text-left" style="margin-left:5px;">일</div>
                            </div>
                        </div>
                        <div class="mt-3">
                            <label class="text-sm text-typo font-weight-bolder ms-1">담당의료진/프로그램 선택</label>
                            <v-select
                                hide-details
                                :items="doctors"
                                color="rgba(0,0,0,.6)"
                                class="
                                       input-style
                                                mt-1
                                      "
                                outlined
                                dense
                                v-model="item.reservationDoctor"
                                @change="onChangeDoctor">
                                <template v-slot:item="{item}">
                                    <div class="text-sm text-body">{{item.name}}</div>
                                </template>
                                <template v-slot:selection="{item}">
                                    <div class="text-sm">{{item.name}}</div>
                                </template>
                            </v-select>
                        </div>
                        <div class="mt-3">
                            <label class="text-sm text-typo font-weight-bolder ms-1">진료형태</label>
                            <v-select
                                hide-details
                                :items="clinicTypes"
                                color="rgba(0,0,0,.6)"
                                class="
                                       input-style
                                                mt-1
                                      "
                                outlined
                                dense
                                v-model="item.reservationClinicType">
                            </v-select>
                        </div>
                        <div class="mt-3">
                            <label class="text-sm text-typo font-weight-bolder ms-1">진료요청내용</label>
                            <v-text-field
                                v-model="item.symptomDescription"
                                hide-details
                                outlined
                                color="rgba(0,0,0,.6)"
                                light
                                class="
                                                font-size-input
                                                placeholder-lighter
                                                text-light-input
                                                border border-radius-md
                                                mt-1
                                                ">
                            </v-text-field>
                        </div>
                        <div class="
                                d-flex
                                flex-column
                                h-100
                                mt-4
                                mb-2
                                ">
                            <div class="d-flex justify-space-between align-center">
                                <h6 class="mb-0 text-typo text-h6 font-weight-bold">
                                    추가 선택/요청 사항
                                </h6>
                                <v-btn
                                    elevation="0"
                                    :ripple="false"
                                    style="height:43px;font-size:14px"
                                    class="
                      font-weight-bold
                      text-capitalize
                      btn-primary
                      bg-gradient-primary
                    "
                                    @click="showAddNonBenefitDialog">
                                    <v-icon class="me-2">fas fa-plus</v-icon>추가
                                </v-btn>
                            </div>
                            <div class="pa-4 bg-gray-100 border-radius-lg mt-4">
                                <div class="d-flex flex-column">
                                    <h6 class="mb-3 text-sm text-typo font-weight-bold">
                                        제증명서
                                    </h6>
                                    <span class="mb-2 text-xs text-body">
                                        {{certificates}}
                                    </span>
                                    <div v-if="certificateEtcs.length > 0">
                                        <div v-for="etc, i in certificateEtcs" :key="i">
                                            <div class="text-sm text-typo font-weight-bold">{{etc.name}}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="d-flex flex-column" style="margin-top:10px">
                                    <h6 class="mb-3 text-sm text-typo font-weight-bold">
                                        비급여 처방/시술
                                    </h6>
                                    <span class="mb-2 text-xs text-body">
                                        {{nonBenefitMedicines}}
                                    </span>
                                </div>
                                <div class="d-flex flex-column" style="margin-top:10px">
                                    <h6 class="mb-3 text-sm text-typo font-weight-bold">
                                        제품/서비스/기타
                                    </h6>
                                    <span class="mb-2 text-xs text-body">
                                        {{etcs}}
                                    </span>
                                </div>
                                <div class="d-flex flex-column" style="margin-top:10px">
                                    <h6 class="mb-3 text-sm text-typo font-weight-bold">
                                        건강검진/검사
                                    </h6>
                                    <span class="mb-2 text-xs text-body">
                                        {{checkups}}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <!--div class="mt-3">
                            <label class="text-sm text-typo font-weight-bolder ms-1">결제방법</label>
                            <v-select
                                hide-details
                                :items="getPayTypes()"
                                color="rgba(0,0,0,.6)"
                                class="
                                       input-style
                                                mt-1
                                      "
                                outlined
                                dense
                                v-model="item.reservationPayType">
                            </v-select>
                        </div-->
                    </v-card-text>
                    <v-card-actions class="pb-0">
                        <v-spacer></v-spacer>
                        <v-btn
                            @click="saveReservation"
                            elevation="0"
                            :ripple="false"
                            height="43"
                            class="
                        font-weight-bold
                        text-capitalize
                        btn-ls btn-primary
                        bg-gradient-primary
                        py-3
                        px-6
                      ">예약하기</v-btn>
                        <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>

    <v-dialog v-model="changeDateDialog" max-width="400px">
        <v-card class="card-shadow border-radius-xl">
            <v-card-text class="d-flex justify-center align-center pt-4">
                <v-date-picker
                    :event-color="'#fd7e14'"
                    :show-current="false"
                    locale="ko"
                    no-title
                    v-model="item.reservationDate"
                    color="#69CBBC"
                    @change="onChangeReservationDate"></v-date-picker>
            </v-card-text>
        </v-card>
    </v-dialog>

    <v-dialog v-model="changeTimeDialog" max-width="700px">
        <v-card class="card-shadow border-radius-xl">
            <v-card-text class="d-flex justify-center align-center pt-4">
                <v-simple-table class="w-100">
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="text-center">
                                    시간
                                </th>
                                <th class="text-center">
                                    예약 가능 고객 수
                                </th>
                                <th class="text-center">
                                    예약고객명
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="(item, i) in reservationTimes"
                                :key="i"
                                :style="isAvailableReservation(item.availableCount)?'cursor:pointer':'background-color:#e9ecef'"
                                @click="onClickReservationTime(item)">
                                <td class="text-center">{{ $moment(item.time).format("HH시 mm분") }}</td>
                                <td class="text-center">{{item.availableCount}}</td>
                                <td class="text-center">{{item.reservationPatients}}</td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-card-text>
            <v-card-actions class="d-flex justify-center align-center mt-2">
                <v-btn
                    @click="changeTimeDialog=false"
                    elevation="0"
                    :ripple="false"
                    height="43"
                    class="
                        font-weight-bold
                        text-capitalize
                        btn-ls
                        bg-gradient-light
                        py-3
                        px-6
                      ">닫기</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <v-dialog v-model="addNonBenefitDialog" max-width="600px">
        <v-card class="card-shadow border-radius-xl">
            <div class="card-header-padding card-border-bottom">
                <span class="font-weight-bold text-h5 text-typo mb-0">비급여 항목 추가</span>
            </div>
            <v-card-text class="py-2">
                <div
                    class="d-flex justify-space-between align-center py-2"
                    style="cursor:pointer"
                    @click="addNonBenefitDrop1=!addNonBenefitDrop1">
                    <div class="text-md font-weight-bold text-typo">제증명서</div>
                    <v-icon>{{addNonBenefitDrop1?'fas fa-chevron-up':'fas fa-chevron-down'}}</v-icon>
                </div>
                <div v-if="addNonBenefitDrop1">
                    <div v-for="certificate, i in addNonBenefitCertificates" :key="i">
                        <v-checkbox
                            v-model="nonBenefitCheckMap[certificate.id]"
                            color="#69CBBC"
                            :ripple="false"
                            class="mx-0 mb-1 mt-0 checkbox-custom checkbox-thinner"
                            hide-details>
                            <template v-slot:label>
                                {{ certificate.name }} ({{ $utils.separateThousands(certificate.price) }}원)
                            </template>
                        </v-checkbox>

                        <div :key="certificate.key">
                            <div v-if="($utils.isEmpty(certificate.selections)==false) && (nonBenefitCheckMap[certificate.id])" class="mt-2">
                                <div v-for="selection, j in certificate.selections" :key="j">
                                    <div class='text-typo text-md font-weight-bold my-1 ms-2'>- 선택 옵션{{ j+1 }}{{ (selection.requiredCount>0)?' (필수 선택 '+selection.requiredCount+')':'' }}</div>
                                    <div v-for="option, k in selection.options" :key="k">
                                        <div class="d-flex justify-start align-center ms-4">
                                            <v-checkbox
                                                v-model="option.checked"
                                                color="#69CBBC"
                                                :ripple="false"
                                                class="mx-0 mb-1 mt-0 checkbox-custom checkbox-thinner"
                                                hide-details>
                                                <template v-slot:label>
                                                    {{ option.name }} ({{ $utils.separateThousands(option.price) }}원)
                                                </template>
                                            </v-checkbox>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!--div v-if="$utils.isEmpty(certificate.selections)==false">
                            <div v-for="selection, j in certificate.selections" :key="j">
                                <div class="d-flex justify-start align-center">
                                    <v-checkbox
                                        v-model="selection.checked"
                                        color="#69CBBC"
                                        :ripple="false"
                                        class="mx-0 mb-1 mt-0 checkbox-custom checkbox-thinner"
                                        hide-details>
                                        <template v-slot:label>
                                            {{ selection.name }} ({{ $utils.separateThousands(selection.price) }}원)
                                        </template>
                                    </v-checkbox>
                                </div>
                            </div>
                        </div-->
                    </div>
                    <div class="d-flex justify-start align-center">
                        <v-checkbox
                            v-model="addNonBenefitCertificateUserReq.checked"
                            color="#69CBBC"
                            :ripple="false"
                            class="mx-0 mb-1 mt-0 checkbox-custom checkbox-thinner"
                            hide-details>
                        </v-checkbox>
                        <v-text-field
                            v-model="addNonBenefitCertificateUserReq.name"
                            hide-details
                            outlined
                            class="
                                input-style
                                font-size-input
                                text-light-input
                                placeholder-lighter
                            "
                            placeholder="기타">
                        </v-text-field>
                    </div>
                </div>
                <div
                    class="d-flex justify-space-between align-center py-2"
                    style="cursor:pointer"
                    @click="addNonBenefitDrop2=!addNonBenefitDrop2">
                    <div class="text-md font-weight-bold text-typo">비급여 처방/시술</div>
                    <v-icon>{{addNonBenefitDrop2?'fas fa-chevron-up':'fas fa-chevron-down'}}</v-icon>
                </div>
                <div v-if="addNonBenefitDrop2">
                    <div v-for="medicine, i in addNonBenefitMedicines" :key="i">
                        <v-checkbox
                            v-model="nonBenefitCheckMap[medicine.id]"
                            color="#69CBBC"
                            :ripple="false"
                            class="mx-0 mb-1 mt-0 checkbox-custom checkbox-thinner"
                            hide-details>
                            <template v-slot:label>
                                {{ medicine.name }} ({{ $utils.separateThousands(medicine.price) }}원)
                            </template>
                        </v-checkbox>

                        <div :key="medicine.key">
                            <div v-if="($utils.isEmpty(medicine.selections)==false) && (nonBenefitCheckMap[medicine.id])" class="mt-2">
                                <div v-for="selection, j in medicine.selections" :key="j">
                                    <div class='text-typo text-md font-weight-bold my-1 ms-2'>- 선택 옵션{{ j+1 }}{{ (selection.requiredCount>0)?' (필수 선택 '+selection.requiredCount+')':'' }}</div>
                                    <div v-for="option, k in selection.options" :key="k">
                                        <div class="d-flex justify-start align-center ms-4">
                                            <v-checkbox
                                                v-model="option.checked"
                                                color="#69CBBC"
                                                :ripple="false"
                                                class="mx-0 mb-1 mt-0 checkbox-custom checkbox-thinner"
                                                hide-details>
                                                <template v-slot:label>
                                                    {{ option.name }} ({{ $utils.separateThousands(option.price) }}원)
                                                </template>
                                            </v-checkbox>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    class="d-flex justify-space-between align-center py-2"
                    style="cursor:pointer"
                    @click="addNonBenefitDrop3=!addNonBenefitDrop3">
                    <div class="text-md font-weight-bold text-typo">제품/서비스/기타</div>
                    <v-icon>{{addNonBenefitDrop3?'fas fa-chevron-up':'fas fa-chevron-down'}}</v-icon>
                </div>
                <div v-if="addNonBenefitDrop3">
                    <div v-for="etc, i in addNonBenefitEtcs" :key="i">
                        <v-checkbox
                            v-model="nonBenefitCheckMap[etc.id]"
                            color="#69CBBC"
                            :ripple="false"
                            class="mx-0 mb-1 mt-0 checkbox-custom checkbox-thinner"
                            hide-details>
                            <template v-slot:label>
                                {{ etc.name }} ({{ $utils.separateThousands(etc.price) }}원)
                            </template>
                        </v-checkbox>

                        <div :key="etc.key">
                            <div v-if="($utils.isEmpty(etc.selections)==false) && (nonBenefitCheckMap[etc.id])" class="mt-2">
                                <div v-for="selection, j in etc.selections" :key="j">
                                    <div class='text-typo text-md font-weight-bold my-1 ms-2'>- 선택 옵션{{ j+1 }}{{ (selection.requiredCount>0)?' (필수 선택 '+selection.requiredCount+')':'' }}</div>
                                    <div v-for="option, k in selection.options" :key="k">
                                        <div class="d-flex justify-start align-center ms-4">
                                            <v-checkbox
                                                v-model="option.checked"
                                                color="#69CBBC"
                                                :ripple="false"
                                                class="mx-0 mb-1 mt-0 checkbox-custom checkbox-thinner"
                                                hide-details>
                                                <template v-slot:label>
                                                    {{ option.name }} ({{ $utils.separateThousands(option.price) }}원)
                                                </template>
                                            </v-checkbox>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    class="d-flex justify-space-between align-center py-2"
                    style="cursor:pointer"
                    @click="addNonBenefitDrop4=!addNonBenefitDrop4">
                    <div class="text-md font-weight-bold text-typo">건강검진/검사</div>
                    <v-icon>{{addNonBenefitDrop4?'fas fa-chevron-up':'fas fa-chevron-down'}}</v-icon>
                </div>
                <div v-if="addNonBenefitDrop4">
                    <div v-for="checkup, i in addNonBenefitCheckups" :key="i">
                        <v-checkbox
                            v-model="nonBenefitCheckMap[checkup.id]"
                            color="#69CBBC"
                            :ripple="false"
                            class="mx-0 mb-1 mt-0 checkbox-custom checkbox-thinner"
                            hide-details
                            @change="checkup.key++">
                            <template v-slot:label>
                                {{ checkup.name }} ({{ $utils.separateThousands(checkup.price) }}원)
                            </template>
                        </v-checkbox>

                        <div :key="checkup.key">
                            <div v-if="($utils.isEmpty(checkup.selections)==false) && (nonBenefitCheckMap[checkup.id])" class="mt-2">
                                <div v-for="selection, j in checkup.selections" :key="j">
                                    <div class='text-typo text-md font-weight-bold my-1 ms-2'>- 선택 옵션{{ j+1 }}{{ (selection.requiredCount>0)?' (필수 선택 '+selection.requiredCount+')':'' }}</div>
                                    <div v-for="option, k in selection.options" :key="k">
                                        <div class="d-flex justify-start align-center ms-4">
                                            <v-checkbox
                                                v-model="option.checked"
                                                color="#69CBBC"
                                                :ripple="false"
                                                class="mx-0 mb-1 mt-0 checkbox-custom checkbox-thinner"
                                                hide-details>
                                                <template v-slot:label>
                                                    {{ option.name }} ({{ $utils.separateThousands(option.price) }}원)
                                                </template>
                                            </v-checkbox>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </v-card-text>

            <v-card-actions class="card-padding d-flex justify-end">
                <v-btn
                    @click="addNonBenefitItems"
                    dark
                    elevation="0"
                    :ripple="false"
                    height="43"
                    class="
                                        font-weight-bold
                                        text-uppercase
                                        btn-default
                                        bg-gradient-primary
                                                    py-3
                                                    px-6
                                                    text-capitalize
                                                "
                    color="primary">추가하기</v-btn>
                <v-btn
                    elevation="0"
                    :ripple="false"
                    height="43"
                    class="
                                                    font-weight-bold
                                                    text-uppercase
                                                    btn-default
                                                    bg-gradient-secondary
                                                    pa-2
                                                    text-capitalize
                                                "
                    color="#5e72e4"
                    @click="addNonBenefitDialog=false">닫기</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <v-snackbar
        centered
        v-model="snackbar"
        :color="snackbarColor"
        class="snackbar-shadow">
        <div class="d-flex align-start alert-notify">
            <v-icon size="24" class="text-white mr-5">ni ni-bell-55</v-icon>
            <p class="mb-0">
                <span class="font-size-root font-weight-600">{{snackbarTitle}}</span>
                <br />
                {{ snackbarMessage }}
            </p>
        </div>
        <template v-slot:action="{ attrs }">
            <v-btn
                icon
                elevation="0"
                max-width="136"
                :ripple="false"
                height="43"
                class="font-weight-600 text-capitalize py-3 px-6 rounded-sm"
                color="rgba(255,255,255, .85)"
                @click="snackbar=false"
                v-bind="attrs">
                <v-icon size="13">fas fa-times</v-icon>
            </v-btn>
        </template>
    </v-snackbar>

    <v-dialog v-model="workingStateDialog" width="300">
        <v-card>
            <v-card-title class="d-flex justify-center">
                <span class="text-body text-sm mb-1">
                    처리중입니다. 잠시만 기다려주세요.
                </span>
            </v-card-title>
        </v-card>
    </v-dialog>
</div>
</template>

<script>
import policyText1 from 'raw-loader!@/assets/new-reservation-policy1.txt';
import policyText2 from 'raw-loader!@/assets/new-reservation-policy2.txt';
import axios from "axios";
export default {
    name: "NewReservationPage",
    props: {
        prop: {
            default: null,
        },
    },
    data: () => ({
        changeDateDialog: false,
        changeTimeDialog: false,
        item: {},
        reservationTimes: [],
        hospital: null,
        doctors: [],
        appUsers: [],
        addNonBenefitDialog: false,
        addNonBenefitDrop1: false,
        addNonBenefitDrop2: false,
        addNonBenefitDrop3: false,
        addNonBenefitDrop4: false,
        addNonBenefitCertificates: [],
        addNonBenefitCertificateEtcs: [],
        addNonBenefitMedicines: [],
        addNonBenefitEtcs: [],
        addNonBenefitCheckups: [],
        addNonBenefitCertificateUserReq: {},
        nonBenefitCheckMap: {},
        certificates: '',
        nonBenefitMedicines: '',
        etcs: '',
        checkups: '',
        certificateEtcs: [],
        workingStateDialog: false,
        snackbar: false,
        snackbarColor: '#ea0606',
        snackbarTitle: '알림',
        snackbarMessage: '',
        phone1: '',
        phone2: '',
        phone3: '',
        policy1Checked: false,
        policy2Checked: false,
        policyText1,
        policyText2,
        adminConfigs: {},
        birthYear: '',
        birthMonth: '',
        birthDay: '',
        clinicTypes: [],
    }),
    watch: {
        async item() {},
    },
    mounted: async function () {
        this.init();
    },
    methods: {
        onChangeDoctor() {
            this.loadClinicTypes();
            if(this.$utils.isEmpty(this.item.reservationDoctor)==false) {
                if(this.item.reservationDoctor.name == '종합검진') {
                    if(this.hospital.hospitalType == 'HospitalType.checkup') {
                        this.addNonBenefitDrop4 = true;
                        this.showAddNonBenefitDialog();
                    }
                }
            }
        },
        getPayTypes() {
            let payTypes = [];
            if(this.$utils.isEmpty(this.hospital.enableAutoPay)==false) {
                if(this.hospital.enableAutoPay) {
                    payTypes = ['자동결제', '직접결제'];
                }
                else {
                    payTypes = ['직접결제'];
                }
            }
            else {
                payTypes = ['자동결제', '직접결제'];
            }
            return payTypes;
        },
        async onChangeReservationDate() {
            if(this.$utils.isEmpty(this.item.reservationDate)==false) {
                let yesterday = new Date();
                yesterday.setDate(yesterday.getDate()-1);
                let reservationDateTime = new Date(this.item.reservationDate + 'T00:00:00');
                if(reservationDateTime < yesterday) {
                    this.item.reservationDate = null;
                    this.showWarningSnackbar('해당 일자는 예약이 마감되었습니다. 다른 날짜를 선택해주세요.');
                }
                else if(reservationDateTime.getDay() == 0) { // sunday
                    if(this.hospital.sundayEnable == false) {
                        this.item.reservationDate = null;
                        this.showWarningSnackbar('병원 휴무일입니다. 다른 날짜를 선택해주세요.');
                    }
                }
                else if(reservationDateTime.getDay() == 1) {
                    if(this.hospital.mondayEnable == false) {
                        this.item.reservationDate = null;
                        this.showWarningSnackbar('병원 휴무일입니다. 다른 날짜를 선택해주세요.');
                    }
                }
                else if(reservationDateTime.getDay() == 2) {
                    if(this.hospital.tuesdayEnable == false) {
                        this.item.reservationDate = null;
                        this.showWarningSnackbar('병원 휴무일입니다. 다른 날짜를 선택해주세요.');
                    }
                }
                else if(reservationDateTime.getDay() == 3) {
                    if(this.hospital.wednesdayEnable == false) {
                        this.item.reservationDate = null;
                        this.showWarningSnackbar('병원 휴무일입니다. 다른 날짜를 선택해주세요.');
                    }
                }
                else if(reservationDateTime.getDay() == 4) {
                    if(this.hospital.thursdayEnable == false) {
                        this.item.reservationDate = null;
                        this.showWarningSnackbar('병원 휴무일입니다. 다른 날짜를 선택해주세요.');
                    }
                }
                else if(reservationDateTime.getDay() == 5) {
                    if(this.hospital.fridayEnable == false) {
                        this.item.reservationDate = null;
                        this.showWarningSnackbar('병원 휴무일입니다. 다른 날짜를 선택해주세요.');
                    }
                }
                else if(reservationDateTime.getDay() == 6) {
                    if(this.hospital.saturdayEnable == false) {
                        this.item.reservationDate = null;
                        this.showWarningSnackbar('병원 휴무일입니다. 다른 날짜를 선택해주세요.');
                    }
                }
            }

            if(this.$utils.isEmpty(this.item.reservationDate)==false) {
                await this.loadAvailableDoctors();
                this.changeDateDialog=false;
            }
        },
        loadClinicTypes() {
            this.item.reservationClinicType = null;
            let clinicTypes = [];
            if (this.$utils.isEmpty(this.item.reservationDoctor) == false) {
                if (this.item.reservationDoctor.availableNormalClinic) {
                    clinicTypes.push('대면진료');
                }
                if (this.item.reservationDoctor.availableOnlineClinic) {
                    if (this.item.reservationDoctor.availableAudioCall) {
                        clinicTypes.push('음성진료');
                    }
                    if (this.item.reservationDoctor.availableVideoCall) {
                        clinicTypes.push('화상진료');
                    }
                }
            }
            this.clinicTypes = clinicTypes;
        },
        compareTimeOnly(date1, date2) { // -1이면 date1이 이전시간
            const time1 = date1.getHours() * 60 * 60 + date1.getMinutes() * 60 + date1.getSeconds();
            const time2 = date2.getHours() * 60 * 60 + date2.getMinutes() * 60 + date2.getSeconds();

            if (time1 < time2) {
                return -1; // 이전 시간
            } else if (time1 > time2) {
                return 1; // 이후 시간
            } else {
                return 0;
            }
        },
        async loadAvailableDoctors() {
            this.item.reservationDoctor = null;
            if (this.$utils.isEmpty(this.item.reservationDate) == false) {
                if (this.$utils.isEmpty(this.item.reservationTime) == false) {
                    try {
                        //console.log('doctors', this.doctors);
                        //console.log('this.item.reservationDate', this.item.reservationDate);
                        //console.log('this.item.reservationTime', this.item.reservationTime);

                        let timeText = this.item.reservationTime.replace('시 ', ':');
                        timeText = timeText.replace('분', ':00');
                        let reservationDateTime = new Date(this.item.reservationDate + 'T' + timeText);

                        let doctors = await this.$database.loadHospitalWorkersByTypeIn(this.hospital.id, ['HospitalWorkerType.doctor', 'HospitalWorkerType.service']);
                        let availableDoctors = [];
                        for (let i = 0; i < doctors.length; i++) {
                            if (doctors[i].enable != true) {
                                continue;
                            }

                            if (reservationDateTime.getDay() == 0) { // sunday
                                if (doctors[i].sundayEnable) {
                                    let openTime = doctors[i].sundayOpenAt.toDate();
                                    let closeTime = doctors[i].sundayCloseAt.toDate();
                                    if (this.compareTimeOnly(reservationDateTime, openTime) >= 0) {
                                        if (this.compareTimeOnly(reservationDateTime, closeTime) <= 0) {
                                            let inLunchTime = false;
                                            // 점심시간이 있을 경우
                                            if (this.$utils.isEmpty(doctors[i].sundayLunchStartAt) == false) {
                                                let lunchStartTime = doctors[i].sundayLunchStartAt.toDate();
                                                let lunchEndTime = doctors[i].sundayLunchEndAt.toDate();
                                                if ((this.compareTimeOnly(reservationDateTime, lunchStartTime) > 0) && (this.compareTimeOnly(reservationDateTime, lunchEndTime) < 0)) {
                                                    inLunchTime = true;
                                                }
                                            }
                                            if (inLunchTime == false) {
                                                availableDoctors.push(doctors[i]);
                                            }
                                        }
                                    }
                                }
                            } else if (reservationDateTime.getDay() == 1) { // monday
                                if (doctors[i].mondayEnable) {
                                    let openTime = doctors[i].mondayOpenAt.toDate();
                                    let closeTime = doctors[i].mondayCloseAt.toDate();
                                    if (this.compareTimeOnly(reservationDateTime, openTime) >= 0) {
                                        if (this.compareTimeOnly(reservationDateTime, closeTime) <= 0) {
                                            let inLunchTime = false;
                                            // 점심시간이 있을 경우
                                            if (this.$utils.isEmpty(doctors[i].mondayLunchStartAt) == false) {
                                                let lunchStartTime = doctors[i].mondayLunchStartAt.toDate();
                                                let lunchEndTime = doctors[i].mondayLunchEndAt.toDate();
                                                if ((this.compareTimeOnly(reservationDateTime, lunchStartTime) > 0) && (this.compareTimeOnly(reservationDateTime, lunchEndTime) < 0)) {
                                                    inLunchTime = true;
                                                }
                                            }
                                            if (inLunchTime == false) {
                                                availableDoctors.push(doctors[i]);
                                            }
                                        }
                                    }
                                }
                            } else if (reservationDateTime.getDay() == 2) { // tuesday
                                if (doctors[i].tuesdayEnable) {
                                    let openTime = doctors[i].tuesdayOpenAt.toDate();
                                    let closeTime = doctors[i].tuesdayCloseAt.toDate();
                                    if (this.compareTimeOnly(reservationDateTime, openTime) >= 0) {
                                        if (this.compareTimeOnly(reservationDateTime, closeTime) <= 0) {
                                            let inLunchTime = false;
                                            // 점심시간이 있을 경우
                                            if (this.$utils.isEmpty(doctors[i].tuesdayLunchStartAt) == false) {
                                                let lunchStartTime = doctors[i].tuesdayLunchStartAt.toDate();
                                                let lunchEndTime = doctors[i].tuesdayLunchEndAt.toDate();
                                                if ((this.compareTimeOnly(reservationDateTime, lunchStartTime) > 0) && (this.compareTimeOnly(reservationDateTime, lunchEndTime) < 0)) {
                                                    inLunchTime = true;
                                                }
                                            }
                                            if (inLunchTime == false) {
                                                availableDoctors.push(doctors[i]);
                                            }
                                        }
                                    }
                                }
                            } else if (reservationDateTime.getDay() == 3) { // wednesday
                                if (doctors[i].wednesdayEnable) {
                                    let openTime = doctors[i].wednesdayOpenAt.toDate();
                                    let closeTime = doctors[i].wednesdayCloseAt.toDate();
                                    if (this.compareTimeOnly(reservationDateTime, openTime) >= 0) {
                                        if (this.compareTimeOnly(reservationDateTime, closeTime) <= 0) {
                                            let inLunchTime = false;
                                            // 점심시간이 있을 경우
                                            if (this.$utils.isEmpty(doctors[i].wednesdayLunchStartAt) == false) {
                                                let lunchStartTime = doctors[i].wednesdayLunchStartAt.toDate();
                                                let lunchEndTime = doctors[i].wednesdayLunchEndAt.toDate();
                                                if ((this.compareTimeOnly(reservationDateTime, lunchStartTime) > 0) && (this.compareTimeOnly(reservationDateTime, lunchEndTime) < 0)) {
                                                    inLunchTime = true;
                                                }
                                            }
                                            if (inLunchTime == false) {
                                                availableDoctors.push(doctors[i]);
                                            }
                                        }
                                    }
                                }
                            } else if (reservationDateTime.getDay() == 4) { // thursday
                                if (doctors[i].thursdayEnable) {
                                    let openTime = doctors[i].thursdayOpenAt.toDate();
                                    let closeTime = doctors[i].thursdayCloseAt.toDate();
                                    if (this.compareTimeOnly(reservationDateTime, openTime) >= 0) {
                                        if (this.compareTimeOnly(reservationDateTime, closeTime) <= 0) {
                                            let inLunchTime = false;
                                            // 점심시간이 있을 경우
                                            if (this.$utils.isEmpty(doctors[i].thursdayLunchStartAt) == false) {
                                                let lunchStartTime = doctors[i].thursdayLunchStartAt.toDate();
                                                let lunchEndTime = doctors[i].thursdayLunchEndAt.toDate();
                                                if ((this.compareTimeOnly(reservationDateTime, lunchStartTime) > 0) && (this.compareTimeOnly(reservationDateTime, lunchEndTime) < 0)) {
                                                    inLunchTime = true;
                                                }
                                            }
                                            if (inLunchTime == false) {
                                                availableDoctors.push(doctors[i]);
                                            }
                                        }
                                    }
                                }
                            } else if (reservationDateTime.getDay() == 5) { // friday
                                if (doctors[i].fridayEnable) {
                                    let openTime = doctors[i].fridayOpenAt.toDate();
                                    let closeTime = doctors[i].fridayCloseAt.toDate();
                                    if (this.compareTimeOnly(reservationDateTime, openTime) >= 0) {
                                        if (this.compareTimeOnly(reservationDateTime, closeTime) <= 0) {
                                            let inLunchTime = false;
                                            // 점심시간이 있을 경우
                                            if (this.$utils.isEmpty(doctors[i].fridayLunchStartAt) == false) {
                                                let lunchStartTime = doctors[i].fridayLunchStartAt.toDate();
                                                let lunchEndTime = doctors[i].fridayLunchEndAt.toDate();
                                                if ((this.compareTimeOnly(reservationDateTime, lunchStartTime) > 0) && (this.compareTimeOnly(reservationDateTime, lunchEndTime) < 0)) {
                                                    inLunchTime = true;
                                                }
                                            }
                                            if (inLunchTime == false) {
                                                availableDoctors.push(doctors[i]);
                                            }
                                        }
                                    }
                                }
                            } else if (reservationDateTime.getDay() == 6) { // saturday
                                if (doctors[i].saturdayEnable) {
                                    let openTime = doctors[i].saturdayOpenAt.toDate();
                                    let closeTime = doctors[i].saturdayCloseAt.toDate();
                                    if (this.compareTimeOnly(reservationDateTime, openTime) >= 0) {
                                        if (this.compareTimeOnly(reservationDateTime, closeTime) <= 0) {
                                            let inLunchTime = false;
                                            // 점심시간이 있을 경우
                                            if (this.$utils.isEmpty(doctors[i].saturdayLunchStartAt) == false) {
                                                let lunchStartTime = doctors[i].saturdayLunchStartAt.toDate();
                                                let lunchEndTime = doctors[i].saturdayLunchEndAt.toDate();
                                                if ((this.compareTimeOnly(reservationDateTime, lunchStartTime) > 0) && (this.compareTimeOnly(reservationDateTime, lunchEndTime) < 0)) {
                                                    inLunchTime = true;
                                                }
                                            }
                                            if (inLunchTime == false) {
                                                availableDoctors.push(doctors[i]);
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        this.doctors = availableDoctors;
                    } catch (e) {
                        console.log(e);
                    }
                }
            }
        },
        showNumOnly(text) {
            this.showWarningSnackbar('숫자만 입력해주세요. (입력된 텍스트 : ' + text + ')');
        },
        showEngNumOnly(text) {
            this.showWarningSnackbar('영문(대소문자 구분) 및 숫자만 입력해주세요. (입력된 텍스트 : ' + text + ')');
        },
        getClinicTypeFullText(item) {
            let clinicType = item.isVideoClinic ? '화상' : '음성';
            if ((item.clinicType != undefined) && (item.clinicType != null)) {
                if (item.clinicType == 'ClinicType.normal') {
                    clinicType = '대면진료';
                } else if (item.clinicType == 'ClinicType.video') {
                    clinicType = '화상통화';
                } else if (item.clinicType == 'ClinicType.audio') {
                    clinicType = '음성통화';
                }
            }
            return clinicType;
        },
        reset() {
            this.item = {};
            this.certificates = '';
            this.nonBenefitMedicines = '';
            this.etcs = '';
            this.checkups = '';
            this.certificateEtcs = [];
            this.phone1 = '';
            this.phone2 = '';
            this.phone3 = '';
            this.birthYear = '';
            this.birthMonth = '';
            this.birthDay = '';
        },
        async loadNonBenefits() {
            try {
                this.certificates = '';
                this.nonBenefitMedicines = '';
                this.etcs = '';
                this.certificateEtcs = [];
                this.checkups = '';
                if (this.$utils.isEmpty(this.item.requestNonBenefits) == false) {
                    for (let i = 0; i < this.item.requestNonBenefits.length; i++) {
                        // 선택정보
                        let selectionsPrice = 0;
                        let selectionsOptions = '';
                        if (this.$utils.isEmpty(this.item.requestNonBenefits[i].selectionsRequests) == false) {
                            let selectionsRequests = this.item.requestNonBenefits[i].selectionsRequests;
                            for (let j = 0; j < selectionsRequests.length; j++) {
                                if (j > 0) {
                                    selectionsOptions += ', ';
                                }
                                selectionsOptions += '선택정보' + (j + 1) + ' : ';
                                if (this.$utils.isEmpty(selectionsRequests[j].options) == false) {
                                    for (let k = 0; k < selectionsRequests[j].options.length; k++) {
                                        let option = selectionsRequests[j].options[k];
                                        selectionsPrice += option.price;
                                        if (k > 0) {
                                            selectionsOptions += ', ';
                                        }
                                        selectionsOptions += option.name + ' ' + this.$utils.separateThousands(option.price + '') + '원';
                                    }
                                } else {
                                    this.item.requestNonBenefits[i].selectionsRequests[j].options = [];
                                }
                            }
                        }

                        // 추가선택정보
                        if (this.$utils.isEmpty(this.item.requestNonBenefits[i].additionalOptions) == false) {
                            let additionalOptions = this.item.requestNonBenefits[i].additionalOptions;
                            for (let k = 0; k < additionalOptions.length; k++) {
                                let option = additionalOptions[k];
                                selectionsPrice += option.price;
                                if (selectionsOptions.length > 0) {
                                    selectionsOptions += ', ';
                                }
                                selectionsOptions += '추가선택정보' + (k + 1) + ' : ';
                                selectionsOptions += option.name + ' ' + this.$utils.separateThousands(option.price + '') + '원';
                            }
                        }

                        // 비급여항목
                        if (this.item.requestNonBenefits[i].type == 'NonBenefitType.certificate') {
                            if (this.certificates.length > 0) {
                                this.certificates += ', ';
                            }
                            this.certificates += this.item.requestNonBenefits[i].name + ' ' +
                                this.$utils.separateThousands(this.item.requestNonBenefits[i].price + '') + '원';
                            if (selectionsOptions.length > 0) {
                                this.certificates += ' (' +
                                    selectionsOptions + ')';
                            }
                            this.certificates += ' : 총 ' + this.$utils.separateThousands((this.item.requestNonBenefits[i].price + selectionsPrice) + '') + '원';
                        }
                        if (this.item.requestNonBenefits[i].type == 'NonBenefitType.nonBenefitMedicine') {
                            if (this.nonBenefitMedicines.length > 0) {
                                this.nonBenefitMedicines += ', ';
                            }
                            this.nonBenefitMedicines += this.item.requestNonBenefits[i].name + ' ' +
                                this.$utils.separateThousands(this.item.requestNonBenefits[i].price + '') + '원';
                            if (selectionsOptions.length > 0) {
                                this.nonBenefitMedicines += ' (' +
                                    selectionsOptions + ')';
                            }
                            this.nonBenefitMedicines += ' : 총 ' + this.$utils.separateThousands((this.item.requestNonBenefits[i].price + selectionsPrice) + '') + '원';
                        }
                        if (this.item.requestNonBenefits[i].type == 'NonBenefitType.etc') {
                            if (this.etcs.length > 0) {
                                this.etcs += ', ';
                            }
                            this.etcs += this.item.requestNonBenefits[i].name + ' ' +
                                this.$utils.separateThousands(this.item.requestNonBenefits[i].price + '') + '원';
                            if (selectionsOptions.length > 0) {
                                this.etcs += ' (' +
                                    selectionsOptions + ')';
                            }
                            this.etcs += ' : 총 ' + this.$utils.separateThousands((this.item.requestNonBenefits[i].price + selectionsPrice) + '') + '원';
                        }
                        if (this.item.requestNonBenefits[i].type == 'NonBenefitType.certificateEtc') {
                            let etc = Object.assign({
                                index: i
                            }, this.item.requestNonBenefits[i]);
                            this.certificateEtcs.push(etc);
                        }
                        if (this.item.requestNonBenefits[i].type == 'NonBenefitType.checkup') {
                            if (this.checkups.length > 0) {
                                this.checkups += ', ';
                            }
                            this.checkups += this.item.requestNonBenefits[i].name + ' ' +
                                this.$utils.separateThousands(this.item.requestNonBenefits[i].price + '') + '원';
                            if (selectionsOptions.length > 0) {
                                this.checkups += ' (' +
                                    selectionsOptions + ')';
                            }
                            this.checkups += ' : 총 ' + this.$utils.separateThousands((this.item.requestNonBenefits[i].price + selectionsPrice) + '') + '원';
                        }
                    }
                }
                if (this.certificates.length == 0) {
                    this.certificates = '없음';
                }
                if (this.nonBenefitMedicines.length == 0) {
                    this.nonBenefitMedicines = '없음';
                }
                if (this.etcs.length == 0) {
                    this.etcs = '없음';
                }
                if (this.checkups.length == 0) {
                    this.checkups = '없음';
                }
            } catch (e) {
                console.log(e);
            }
        },
        async addNonBenefitItems() {
            this.workingStateDialog = true;
            let error = false;
            for (let type = 0; type < 4; type++) {
                let nonBenefits = [];
                if (type == 0) {
                    nonBenefits = this.addNonBenefitCertificates;
                } else if (type == 1) {
                    nonBenefits = this.addNonBenefitMedicines;
                } else if (type == 2) {
                    nonBenefits = this.addNonBenefitEtcs;
                } else if (type == 3) {
                    nonBenefits = this.addNonBenefitCheckups;
                }
                for (let i = 0; i < nonBenefits.length; i++) {
                    let nonBenefit = nonBenefits[i];
                    let checked = this.nonBenefitCheckMap[nonBenefit.id];
                    if (checked) {
                        if (this.$utils.isEmpty(nonBenefit.selections) == false) {
                            let selectionsRequests = [];
                            for (let j = 0; j < nonBenefit.selections.length; j++) {
                                let selection = nonBenefit.selections[j];
                                console.log('selection', selection);

                                let requiredCount = 0;
                                if (this.$utils.isEmpty(selection.requiredCount) == false) {
                                    requiredCount = selection.requiredCount;
                                }
                                if (this.$utils.isEmpty(selection.options) == false) {
                                    let checkedCount = 0;
                                    let optionIds = [];
                                    let options = [];
                                    for (let k = 0; k < selection.options.length; k++) {
                                        if (selection.options[k].checked) {
                                            optionIds.push(selection.options[k].id);
                                            options.push(selection.options[k]);
                                            checkedCount++;
                                        }
                                    }
                                    if (checkedCount != requiredCount) {
                                        this.showWarningSnackbar(nonBenefit.name + '의 선택 옵션' + (j + 1) + '에서 반드시 ' + requiredCount + '개를 선택해주세요.');
                                        error = true;
                                        break;
                                    }
                                    selectionsRequests.push({
                                        optionIds: optionIds,
                                        options: options
                                    });
                                }
                                if (error) {
                                    break;
                                }
                            }
                            if (selectionsRequests.length > 0) {
                                nonBenefit.selectionsRequests = selectionsRequests;
                            }
                        }
                        if (error) {
                            break;
                        }
                        if (this.$utils.isEmpty(this.item.requestNonBenefits)) {
                            this.item.requestNonBenefits = [];
                        }
                        this.item.requestNonBenefits.push(nonBenefit);
                    }
                }
            }
            if (error == false) {
                if (this.addNonBenefitCertificateUserReq.checked) {
                    let model = this.$models.NonBenefitModel.create();
                    model.createAt = this.$database.currentTimestamp();
                    model.type = 'NonBenefitType.certificateEtc';
                    model.name = '기타(' + this.addNonBenefitCertificateUserReq.name + ')';
                    if (this.$utils.isEmpty(this.item.requestNonBenefits)) {
                        this.item.requestNonBenefits = [];
                    }
                    this.item.requestNonBenefits.push(model);
                }
            }
            if (error == false) {
                this.workingStateDialog = false;
                this.loadNonBenefits();
                this.addNonBenefitDialog = false;
                this.showInfoSnackbar('추가되었습니다.');
            } else {
                this.workingStateDialog = false;
            }
        },
        showAddNonBenefitDialog() {
            this.nonBenefitCheckMap = {};
            for (let type = 0; type < 4; type++) {
                let nonBenefits = [];
                if (type == 0) {
                    nonBenefits = this.addNonBenefitCertificates;
                } else if (type == 1) {
                    nonBenefits = this.addNonBenefitMedicines;
                } else if (type == 2) {
                    nonBenefits = this.addNonBenefitEtcs;
                } else if (type == 3) {
                    nonBenefits = this.addNonBenefitCheckups;
                }
                for (let i = 0; i < nonBenefits.length; i++) {
                    let nonBenefit = nonBenefits[i];
                    if (this.$utils.isEmpty(nonBenefit.selections) == false) {
                        for (let j = 0; j < nonBenefit.selections.length; j++) {
                            let selection = nonBenefit.selections[j];
                            if (this.$utils.isEmpty(selection.options) == false) {
                                for (let k = 0; k < selection.options.length; k++) {
                                    selection.options[k].checked = false;
                                }
                            }
                        }
                    }
                }
            }
            this.addNonBenefitCertificateUserReq.name = '';
            this.addNonBenefitCertificateUserReq.checked = false;
            this.addNonBenefitDialog = true;
        },
        getAge(item) {
            let result = '';
            if (this.$utils.isEmpty(item.birth) == false) {
                let birth = this.$database.timestampToDate(item.birth);
                let today = new Date();
                let age = today.getFullYear() - birth.getFullYear();
                let month = today.getMonth() - birth.getMonth();
                if (month < 0 || (month === 0 && today.getDate() < birth.getDate())) {
                    age--;
                }
                result = age + ', ';
            }
            return result;
        },
        async init() {
            this.hospitalCode = this.$route.query.code;
            console.log('this.hospitalCode', this.hospitalCode);
            try {
                this.adminConfigs = await this.$database.loadAdminConfig('appData');
                this.hospital = await this.$database.loadHospitalByHospitalCode(this.hospitalCode);
                if (this.$utils.isEmpty(this.hospital) == false) {
                    this.doctors = [];
                    let allUsersMap = {};
                    let allUsers = await this.$database.loadUsersAll();
                    let hospitalPatientsMap = {};
                    for (let i = 0; i < allUsers.length; i++) {
                        if (this.$utils.isEmpty(allUsers[i].name) == false) {
                            allUsersMap[allUsers[i].id] = allUsers[i];
                            if (allUsers[i].currentHospitalId == this.hospital.id) {
                                hospitalPatientsMap[allUsers[i].id] = allUsers[i];
                            }
                        }
                    }
                    let hospitalWorks = await this.$database.loadHospitalWorksAll(this.hospital.id);
                    for (let i = 0; i < hospitalWorks.length; i++) {
                        if (this.$utils.isEmpty(hospitalPatientsMap[hospitalWorks[i].userId])) {
                            if (this.$utils.isEmpty(allUsersMap[hospitalWorks[i].userId]) == false) {
                                hospitalPatientsMap[hospitalWorks[i].userId] = allUsersMap[hospitalWorks[i].userId];
                            }
                        }
                    }
                    let keys = Object.keys(hospitalPatientsMap);
                    let appUsers = [];
                    for (let i = 0; i < keys.length; i++) {
                        appUsers.push(hospitalPatientsMap[keys[i]]);
                    }
                    appUsers.sort((a, b) => {
                        if (a.name < b.name) {
                            return -1;
                        } else if (a.name > b.name) {
                            return 1;
                        } else {
                            return 0;
                        }
                    });
                    this.appUsers = appUsers;

                    // 비급여항목
                    let nonBenefits = await this.$database.loadNonBenefits(this.hospital.id);
                    for (let i = 0; i < nonBenefits.length; i++) {
                        nonBenefits[i].createAtText = this.$moment(nonBenefits[i].createAt.toDate()).format("YYYY/MM/DD HH:mm:ss");
                        if (nonBenefits[i].type == 'NonBenefitType.certificate') {
                            nonBenefits[i].typeText = '제증명서';
                            this.addNonBenefitCertificates.push(nonBenefits[i]);
                        } else if (nonBenefits[i].type == 'NonBenefitType.certificateEtc') {
                            nonBenefits[i].typeText = '제증명서';
                            this.addNonBenefitCertificateEtcs.push(nonBenefits[i]);
                        } else if (nonBenefits[i].type == 'NonBenefitType.nonBenefitMedicine') {
                            nonBenefits[i].typeText = '비급여처방/시술';
                            this.addNonBenefitMedicines.push(nonBenefits[i]);
                        } else if (nonBenefits[i].type == 'NonBenefitType.etc') {
                            nonBenefits[i].typeText = '제품/서비스/기타';
                            this.addNonBenefitEtcs.push(nonBenefits[i]);
                        } else if (nonBenefits[i].type == 'NonBenefitType.checkup') {
                            nonBenefits[i].typeText = '건강검진/검사';
                            nonBenefits[i].key = 0;
                            this.addNonBenefitCheckups.push(nonBenefits[i]);
                        }
                        this.nonBenefitCheckMap[nonBenefits[i].id] = false;
                    }
                    this.addNonBenefitCertificateUserReq.name = '';
                    this.addNonBenefitCertificateUserReq.checked = false;

                    this.loadNonBenefits();
                } else {
                    this.showWarningSnackbar('병원 정보를 확인할 수 없습니다.');
                }
            } catch (e) {
                console.log(e);
            }
        },
        isAvailableReservation(availableCount) {
            if ((availableCount == '휴게시간') || (availableCount == '0명') || (availableCount == '마감')) {
                return false;
            } else {
                return true;
            }
        },
        async onClickReservationTime(reservationTime) {
            if (this.isAvailableReservation(reservationTime.availableCount)) {
                this.item.reservationTime = this.$moment(reservationTime.time).format("HH시 mm분");
                this.changeTimeDialog = false;
                await this.loadAvailableDoctors();
                if(this.doctors.length == 0) {
                    this.item.reservationTime = null;
                    this.showWarningSnackbar('해당 진료일시에 예약 가능한 의료진 및 프로그램이 없습니다.');
                }
            }
        },
        async saveReservation() {
            if (this.policy1Checked == false) {
                this.showWarningSnackbar('약관에 동의해주세요.');
            } else if (this.policy2Checked == false) {
                this.showWarningSnackbar('약관에 동의해주세요.');
            } else if (this.$utils.isEmpty(this.item.reservationDate)) {
                this.showWarningSnackbar('예약일자를 선택해주세요.');
            } else if (this.$utils.isEmpty(this.item.reservationTime)) {
                this.showWarningSnackbar('예약시간을 선택해주세요.');
            } else if (this.$utils.isEmpty(this.item.name)) {
                this.showWarningSnackbar('예약자 이름을 입력해주세요.');
            } else if (this.$utils.isEmpty((this.phone1.length == 0) || (this.phone2.length == 0) || (this.phone3.length == 0))) {
                this.showWarningSnackbar('예약자 핸드폰번호를 입력해주세요.');
            } else if ((this.birthYear.length == 0) || (this.birthMonth.length == 0) || (this.birthDay.length == 0)) {
                this.showWarningSnackbar('생년월일을 입력해주세요.');
            } else if (this.birthYear.length < 4) {
                this.showWarningSnackbar('생년월일을 정확히 입력해주세요.');
            } else if ((this.birthMonth * 1 < 1) || (this.birthMonth * 1 > 12)) {
                this.showWarningSnackbar('생년월일을 정확히 입력해주세요.');
            } else if ((this.birthDay * 1 < 1) || (this.birthDay * 1 > 31)) {
                this.showWarningSnackbar('생년월일을 정확히 입력해주세요.');
            } else if (this.$utils.isEmpty(this.item.reservationDoctor)) {
                this.showWarningSnackbar('담당의사를 선택해주세요.');
            } else if (this.$utils.isEmpty(this.item.reservationClinicType)) {
                this.showWarningSnackbar('진료형태를 선택해주세요.');
            } /*else if (this.$utils.isEmpty(this.item.reservationPayType)) {
                this.showWarningSnackbar('결제방법을 선택해주세요.');
            }*/ else {
                this.item.phone = this.phone1 + this.phone2 + this.phone3;
                this.item.reservationPayType = '직접결제'; // 항상 직접결제
                let birthDayText = this.$utils.lpad(this.birthYear, '0', 2) + '-' + this.$utils.lpad(this.birthMonth, '0', 2) + '-' + this.$utils.lpad(this.birthDay, '0', 2);
                let birthDate = new Date(birthDayText + 'T00:00:00');

                this.workingStateDialog = true;
                let appUser = null;
                let appUsers = await this.$database.loadUsersByPhone(this.item.phone);
                for (let i = 0; i < appUsers.length; i++) {
                    if (appUsers[i].name == this.item.name) {
                        appUser = appUsers[i];
                    } else {
                        appUser = null;
                    }
                }
                if (appUser == null) {
                    let newUser = {
                        createAt: this.$database.currentTimestamp(),
                        idCode: '',
                        passwordChangeAt: this.$database.currentTimestamp(),
                        recentLoginVersion: null,
                        recentLoginAt: null,
                        email: this.item.phone + '-patient' + appUsers.length + '@doctor365hospital.app',
                        name: this.item.name,
                        residentNumber: '',
                        phone: this.item.phone,
                        birth: this.$database.dateToTimestamp(birthDate),
                        gender: null,
                        recommendHospitalCode: this.hospital.hospitalCode,
                        recommendHospitalId: this.hospital.id,
                        medicationInUse: null,
                        selectAddress: null,
                        address: null,
                        doroAddress: null,
                        addressDetail: null,
                        addressAdditionalInfo: null,
                        addressPostCode: null,
                        allergy: null,
                        diseases: {},
                        profileImageUrl: null,
                        profileImageBlurHash: null,
                        userType: null,
                        membershipType: null,
                        signUpMethod: 'SignUpMethod.email',
                        point: null,
                        myInvitationLink: null,
                        allowMarketing: true,
                        allowMarketingAt: null,
                        allowServicePush: true,
                        allowServicePushAt: null,
                        pushToken: null,
                        currentHospitalId: this.hospital.id,
                        favoriteHospitals: [this.hospital.id],
                        favoritePharmacys: [],
                        hasSeenHospitalNotice: [],
                        hasSeenNotificationTime: null,
                        recentPharmacyIds: [],
                        myMainCardId: null,
                        myMainCardBillingKey: null,
                        certificationCi: null,
                        certificationDi: null,
                        isForeigner: null,
                        isPregnant: null,
                        pregnancyMonth: null,
                        isBreastfeeding: null,
                        webSignUp: true,
                    };
                    let userId = await this.$database.addUser(newUser);
                    newUser.userId = userId;
                    let result = await this.$functions.call('createUser2', {
                        'uid': userId,
                        'email': newUser.email,
                        'password': newUser.phone
                    });
                    if (result.data.result == true) {
                        appUser = newUser;
                    } else {
                        this.workingStateDialog = false;
                        this.showWarningSnackbar('예약에 실패하였습니다. ' + result.data.error);
                    }
                }
                if (appUser) {
                    let timeText = this.item.reservationTime.replace('시 ', ':');
                    timeText = timeText.replace('분', ':00');
                    let reservationDateTime = new Date(this.item.reservationDate + 'T' + timeText);
                    let reservationTimestamp = this.$database.dateToTimestamp(reservationDateTime);
                    let clinicType = '';
                    if (this.item.reservationClinicType == '대면진료') {
                        clinicType = 'ClinicType.normal';
                    } else if (this.item.reservationClinicType == '음성진료') {
                        clinicType = 'ClinicType.audio';
                    } else if (this.item.reservationClinicType == '화상진료') {
                        clinicType = 'ClinicType.video';
                    }
                    let isAutoPay = true;
                    if (this.item.reservationPayType == '직접결제') {
                        isAutoPay = false;
                    }
                    try {
                        if (this.$utils.isEmpty(this.item.symptomDescription)) {
                            this.item.symptomDescription = '';
                        }
                        let billingKey = '';
                        if (this.$utils.isEmpty(appUser) == false) {
                            billingKey = appUser.myMainCardBillingKey;
                        }
                        if (this.$utils.isEmpty(this.item.requestNonBenefits)) {
                            this.item.requestNonBenefits = [];
                        }
                        let newItem = Object.assign(this.$models.HospitalWorkModel.create(), {
                            directReservation: true,
                            createAt: this.$database.currentTimestamp(),
                            reservationDateTime: reservationTimestamp,
                            patientId: appUser.id,
                            patientFamilyId: '본인',
                            patientName: appUser.name,
                            patientAddress: appUser.address,
                            patientAddressDetail: appUser.addressDetail,
                            patientPhone: appUser.phone,
                            patientResidentNumber: appUser.residentNumber,
                            patientGender: appUser.gender,
                            patientBirth: appUser.birth,
                            medicationInUse: appUser.medicationInUse,
                            allergy: appUser.allergy,
                            diseases: appUser.diseases,
                            isSecondTimeClinic: false,
                            clinicType: clinicType,
                            isVideoClinic: (clinicType == 'ClinicType.video') ? true : false,
                            isAutoPay: isAutoPay,
                            symptomDescription: this.item.symptomDescription,
                            images: [],
                            requestNonBenefits: this.item.requestNonBenefits,
                            hospitalId: this.hospital.id,
                            hospitalName: this.hospital.name,
                            doctorId: this.item.reservationDoctor.id,
                            doctorJob: this.item.reservationDoctor.job,
                            doctorName: this.item.reservationDoctor.name,
                            patientUserMemo: '',
                            clinicState: 'ClinicState.waiting',
                            isUserReceivedPayMessage: false,
                            billingKey: billingKey,
                            patientRelationWithUser: '본인',
                            familyCertificateUrl: null,
                            payFail: false
                        });
                        // 초기화
                        newItem.prescriptionFileUrl = null;
                        newItem.nextClinicDate = null;
                        newItem.nextScheduleAfter = null;
                        newItem.paymentAmount = null;
                        newItem.priceBenefit = null;
                        newItem.priceDelivery = null;
                        newItem.priceNonBenefit = null;
                        newItem.pricePoint = null;
                        newItem.settleAmount = null;
                        newItem.waitingTimeMinutes = null;

                        let id = await this.$database.addHospitalWork(newItem);
                        newItem.id = id;
                        let smsMessage = this.adminConfigs.reservationMessage;
                        if (this.$utils.isEmpty(this.adminConfigs.reservationMessage)) {
                            smsMessage = '';
                        }
                        let reservationDateTime = newItem.reservationDateTime.toDate();
                        let reservationDateText = (reservationDateTime.getFullYear()) + '년 ' + (reservationDateTime.getMonth() + 1) + '월 ' + (reservationDateTime.getDate()) + '일';
                        let reservationTimeText = (reservationDateTime.getHours()) + '시 ' + this.$utils.lpad(reservationDateTime.getMinutes() + '', '0', 2) + '분';
                        smsMessage = smsMessage.replaceAll('{환자명}', newItem.patientName);
                        smsMessage = smsMessage.replaceAll('{날짜}', reservationDateText);
                        smsMessage = smsMessage.replaceAll('{시간}', reservationTimeText);
                        smsMessage = smsMessage.replaceAll('{병원명}', newItem.hospitalName);
                        smsMessage = smsMessage.replaceAll('{내용}', newItem.symptomDescription);
                        smsMessage = smsMessage.replaceAll('{병원별 URL}', this.hospital.hospitalCodeUrl);
                        smsMessage = smsMessage.replaceAll('{병원주소}', this.hospital.address);
                        smsMessage = smsMessage.replaceAll('{병원지도링크}', 'http://map.naver.com/?dlevel=13&lat=' + this.hospital.gpsX + '&lng=' + this.hospital.gpsY);
                        smsMessage = smsMessage.replaceAll('{병원전화번호}', this.$utils.getTelPhoneText(this.hospital.phone));
                        await this.sendSmsMessage(newItem.patientPhone, smsMessage);
                        this.workingStateDialog = false;
                        this.changeReservationDialog = false;
                        this.showInfoSnackbar('저장되었습니다.');
                        //await this.loadItem();
                        this.reset();
                    } catch (e) {
                        console.log(e);
                    }
                } else {
                    this.workingStateDialog = false;
                    this.showWarningSnackbar('예약에 실패하였습니다.');
                }
            }
        },
        async sendSmsMessage(phone, message) {
            let data = {
                sms: message,
                number: phone
            };
            await axios.post('https://us-central1-doctor365-9a68d.cloudfunctions.net/sendSMS', JSON.stringify(data), {
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
                .then(async function (response) {
                    if (response.data != undefined) {
                        let responseData = response.data;
                        if (responseData.message != 'success') {
                            console.log('문자 전송에 실패했습니다. ', responseData.message);
                        }
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    this.disableSendAuthButton = false;
                });
        },
        async showChangeTimeDialog() {
            this.reservationTimes = [];

            let reservationTimes = [];
            let openTime = new Date(this.item.reservationDate + 'T00:00:00');
            let closeTime = new Date(openTime);
            let lunchStartTime = null;
            let lunchEndTime = null;
            let interval = 30;
            let patientCountLimit = 5;

            // 1. 병원 설정 정보 가져오기
            if (openTime.getDay() == 0) { // sunday
                if (this.hospital.sundayEnable) {
                    openTime = this.hospital.sundayOpenAt.toDate();
                    closeTime = this.hospital.sundayCloseAt.toDate();
                    if (this.$utils.isEmpty(this.hospital.sundayLunchStartAt) == false) {
                        lunchStartTime = this.hospital.sundayLunchStartAt.toDate();
                        lunchEndTime = this.hospital.sundayLunchEndAt.toDate();
                    }
                }
            } else if (openTime.getDay() == 1) { // monday
                if (this.hospital.mondayEnable) {
                    openTime = this.hospital.mondayOpenAt.toDate();
                    closeTime = this.hospital.mondayCloseAt.toDate();
                    if (this.$utils.isEmpty(this.hospital.mondayLunchStartAt) == false) {
                        lunchStartTime = this.hospital.mondayLunchStartAt.toDate();
                        lunchEndTime = this.hospital.mondayLunchEndAt.toDate();
                    }
                }

            } else if (openTime.getDay() == 2) { // tuesday
                if (this.hospital.tuesdayEnable) {
                    openTime = this.hospital.tuesdayOpenAt.toDate();
                    closeTime = this.hospital.tuesdayCloseAt.toDate();
                    if (this.$utils.isEmpty(this.hospital.tuesdayLunchStartAt) == false) {
                        lunchStartTime = this.hospital.tuesdayLunchStartAt.toDate();
                        lunchEndTime = this.hospital.tuesdayLunchEndAt.toDate();
                    }
                }

            } else if (openTime.getDay() == 3) { // wednesday
                if (this.hospital.wednesdayEnable) {
                    openTime = this.hospital.wednesdayOpenAt.toDate();
                    closeTime = this.hospital.wednesdayCloseAt.toDate();
                    if (this.$utils.isEmpty(this.hospital.wednesdayLunchStartAt) == false) {
                        lunchStartTime = this.hospital.wednesdayLunchStartAt.toDate();
                        lunchEndTime = this.hospital.wednesdayLunchEndAt.toDate();
                    }
                }

            } else if (openTime.getDay() == 4) { // thursday
                if (this.hospital.thursdayEnable) {
                    openTime = this.hospital.thursdayOpenAt.toDate();
                    closeTime = this.hospital.thursdayCloseAt.toDate();
                    if (this.$utils.isEmpty(this.hospital.thursdayLunchStartAt) == false) {
                        lunchStartTime = this.hospital.thursdayLunchStartAt.toDate();
                        lunchEndTime = this.hospital.thursdayLunchEndAt.toDate();
                    }
                }

            } else if (openTime.getDay() == 5) { // friday
                if (this.hospital.fridayEnable) {
                    openTime = this.hospital.fridayOpenAt.toDate();
                    closeTime = this.hospital.fridayCloseAt.toDate();
                    if (this.$utils.isEmpty(this.hospital.fridayLunchStartAt) == false) {
                        lunchStartTime = this.hospital.fridayLunchStartAt.toDate();
                        lunchEndTime = this.hospital.fridayLunchEndAt.toDate();
                    }
                }

            } else if (openTime.getDay() == 6) { // saturday
                if (this.hospital.saturdayEnable) {
                    openTime = this.hospital.saturdayOpenAt.toDate();
                    closeTime = this.hospital.saturdayCloseAt.toDate();
                    if (this.$utils.isEmpty(this.hospital.saturdayLunchStartAt) == false) {
                        lunchStartTime = this.hospital.saturdayLunchStartAt.toDate();
                        lunchEndTime = this.hospital.saturdayLunchEndAt.toDate();
                    }
                }
            }
            if (this.$utils.isEmpty(this.hospital.reservationInvervalMinutes) == false) {
                interval = this.hospital.reservationInvervalMinutes * 1;
            }
            if (this.$utils.isEmpty(this.hospital.reservationPatientNumber) == false) {
                patientCountLimit = this.hospital.reservationPatientNumber;
            }
            openTime = new Date(this.item.reservationDate + 'T' + this.$moment(openTime).format("HH:mm") + ':00');
            closeTime = new Date(this.item.reservationDate + 'T' + this.$moment(closeTime).format("HH:mm") + ':00');
            lunchStartTime = new Date(this.item.reservationDate + 'T' + this.$moment(lunchStartTime).format("HH:mm") + ':00');
            lunchEndTime = new Date(this.item.reservationDate + 'T' + this.$moment(lunchEndTime).format("HH:mm") + ':00');

            //console.log('openTime', openTime);
            //console.log('closeTime', closeTime);
            // console.log('lunchStartTime', lunchStartTime);
            // console.log('lunchEndTime', lunchEndTime);
            // console.log('interval', interval);
            // console.log('patientCountLimit', patientCountLimit);
            this.patientCountLimit = patientCountLimit;

            // 2. 예약정보 불러오기
            let hospitalWorks = await this.$database.loadHospitalWorks(this.hospital.id,
                this.$database.dateToTimestamp(openTime),
                this.$database.dateToTimestamp(closeTime),
                '',
                ['ClinicState.waiting', 'ClinicState.accepted', 'ClinicState.done', 'ClinicState.inProgress'],
                null);

            //console.log('hospitalWorks', hospitalWorks);

            // 시간대별로 loop
            for (let dateTime = openTime; dateTime < closeTime; dateTime.setMinutes(dateTime.getMinutes() + interval)) {
                let availableCount = '';
                let reservationPatients = '';
                // 휴게시간이 있을 경우
                //console.log('lunchStartTime', lunchStartTime);
                //console.log('lunchEndTime', lunchEndTime);
                //console.log('dateTime', dateTime);
                if ((lunchStartTime != null) && (lunchEndTime != null)) {
                    if ((dateTime >= lunchStartTime) && (dateTime < lunchEndTime)) {
                        availableCount = '휴게시간';
                    }
                }
                if(availableCount != '휴게시간') {
                    let now = new Date();
                    if(this.$utils.isSameDay(now, dateTime)) {
                        now.setFullYear(dateTime.getFullYear());
                        now.setMonth(dateTime.getMonth());
                        now.setDate(dateTime.getDate());
                        if(dateTime < now) {
                            availableCount = '마감';
                        }
                    }
                }
                if ((availableCount != '휴게시간') && (availableCount != '마감')) {
                    let durationStart = new Date(dateTime);
                    let durationEnd = new Date(dateTime);
                    durationEnd.setMinutes(durationEnd.getMinutes() + interval);
                    //console.log('durationStart', durationStart);
                    //console.log('durationEnd', durationEnd);
                    let patientCount = 0;
                    for (let i = 0; i < hospitalWorks.length; i++) {
                        let reservationTime = hospitalWorks[i].reservationDateTime.toDate();
                        //console.log('reservationTime', reservationTime);
                        if ((reservationTime >= durationStart) && (reservationTime < durationEnd)) {
                            patientCount++;
                            if (reservationPatients.length > 0) {
                                reservationPatients += ', ';
                            }
                            reservationPatients += hospitalWorks[i].patientName;
                            //console.log('patientCount', patientCount);
                        }
                    }
                    availableCount = patientCountLimit - patientCount;
                    if (availableCount < 0) {
                        availableCount = 0;
                    }
                    availableCount = availableCount + '명';
                    //console.log('availableCount', availableCount);
                }
                reservationTimes.push({
                    time: new Date(dateTime),
                    availableCount: availableCount,
                    reservationPatients: reservationPatients
                });
            }

            //console.log('reservationTimes', reservationTimes);
            this.reservationTimes = reservationTimes;
            this.changeTimeDialog = true;
        },
        showWarningSnackbar(message) {
            this.snackbarColor = this.$theme.danger;
            this.snackbarMessage = message;
            this.snackbar = true;
        },
        showInfoSnackbar(message) {
            this.snackbarColor = this.$theme.primary;
            this.snackbarMessage = message;
            this.snackbar = true;
        },
    },
};
</script>
